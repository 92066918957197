import { Icon } from '@iconify/react';
import { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import SearchBox from 'components/search_box';
import Button from 'silal_app_base_react/src/components/buttons';
import Checkbox from 'silal_app_base_react/src/components/checkbox';
import Customselect, {
  Option,
  OptionWithObj,
} from 'silal_app_base_react/src/components/custom_select';
import Heading from 'components/headings/main_heading';
import SubHeading from 'components/headings/sub_heading';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import { Wrapper } from './review_attributes.styled';
import { ManagementCategory } from 'data/types/categories';
import { Attribute, SelectAttribute } from 'data/types/attributes';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import MainHeading from 'components/headings/main_heading';
import backarrow from 'assets/images/backarrow.svg';
import { buildAttributeKey } from '../functions/review_attributes_fucntions';
import { ConfigProvider, Select } from 'antd';
import CategoriesRepository from 'data/repositories/categories_repository';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'data/types/state';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import CategoryModal from 'silal_app_base_react/src/pages/create_listings/modals/select_category_modal';
import { Button as AntdButton, Modal } from 'antd';
import { AbstractCategoryWithSubcategories } from 'silal_app_base_react/src/data/types/categories';
import { stringify } from 'querystring';
import { Col, Row } from 'react-bootstrap';
import {
  Tag,
  Input,
  Space,
  Table,
  InputRef,
  TableColumnsType,
  TableColumnType,
} from 'antd';
import type { TableProps } from 'antd';
import React from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { Checkbox as Check } from '@nextui-org/react';
import { AutoComplete } from 'antd';
import { CheckIn } from 'akar-icons';
type AlignType = 'left' | 'right' | 'center';

type DataIndex = keyof SolrResponseDoc;
type SolrResponseDoc = {
  id: string;
  listing_id: number;
  name_en: string;
  [key: string]: any;
};

function ReviewAttributes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );

  const [
    selectedCategoryAttributesOptions,
    setSelectedCategoryAttributesOptions,
  ] = useState<OptionWithObj[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<ManagementCategory>();
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>();
  const [attrKey, setAttrKey] = useState<string>('');

  const [listings, setListings] = useState<SolrResponseDoc[]>([]);
  const [listingsWithNull, setListingsWithNull] = useState<SolrResponseDoc[]>(
    [],
  );

  const [updateSelectedListings, setUpdateSelectedListings] = useState<
    SolrResponseDoc[]
  >([]);
  const [bulkSelectedListings, setBulkSelectedListings] = useState<
    SolrResponseDoc[]
  >([]);

  const [checkAllForUpdate, setCheckAllForUpdate] = useState<boolean>(false);
  const [checkAllForBulk, setCheckAllForBulk] = useState<boolean>(false);

  const [showNullOnly, setShowNullOnly] = useState<boolean>(false);

  const [bulkValue, setBulkValue] = useState<string>();
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const { categories = [] }: { categories: ManagementCategory[] } = useSelector(
    (state: RootState) => ({ categories: state.Categories.categories }),
  );
  const [targetCategory, setTargetCategory] = useState<ManagementCategory>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    submit();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(actionGetCategoriesMinimalList());
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const number = Number(pathSegments[pathSegments.length - 2]);
    const category = async (number: number) => {
      if (isNaN(number)) return;
      await SharedRepository.getCategoryBrief(number, true).then(
        (category: any) => {
          if (!category) return;
          setSelectedCategory(category);
          setSelectedAttribute(undefined);
          setAttrKey('');
        },
      );
      setShowCategoryModal(false);
    };
    category(number);
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategoryAttributesOptions(
        selectedCategory.attributes?.map((i: Attribute) => ({
          label: i,
          value: i.name_en + ' - ' + i.name_ar,
        })) || [],
      );
    }
  }, [selectedCategory]);

  useEffect(() => {
    async function fetchCatgeoryListings() {
      await SolrRepository.getListingsOfCategoryAndSubcategories(
        selectedCategory!.id.toString(),
      ).then((res) => {
        if (!res) return;
        setListings(res);
      });
    }
    if (selectedCategory) {
      fetchCatgeoryListings();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedAttribute) {
      setAttrKey(buildAttributeKey(selectedAttribute));
      setListingsWithNull(listings.filter((i: any) => !i[attrKey]));
    }
  }, [selectedAttribute, listings]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(String(dataIndex));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<SolrResponseDoc> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <AntdButton
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </AntdButton>
          <AntdButton
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </AntdButton>
          <AntdButton
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(String(dataIndex));
            }}
          >
            Filter
          </AntdButton>
          <AntdButton
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </AntdButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderTypeFormat = (listing: any, bulkValueOnChange: any = null) => {
    const handleChange = (e: any, listing: any) => {
      if (bulkValueOnChange) {
        bulkValueOnChange(e.target.checked);
      } else {
        setListings(
          listings?.map((i: any) => {
            if (listing.listing_id === i.listing_id) {
              i[attrKey] = e.target.checked;
            }
            return i;
          }),
        );
      }
    };

    // Select
    if (selectedAttribute?.type === AttributeType.SELECT) {
      return (
        <ConfigProvider
          theme={{
            components: {
              Select: {
                optionActiveBg: 'green',
              },
            },
          }}
        >
          <AutoComplete
            value={listing[attrKey]}
            options={
              (selectedAttribute as SelectAttribute)?.official_values_en?.map(
                (i: string, index: number) =>
                  ({
                    label:
                      i +
                      ' - ' +
                      (selectedAttribute as SelectAttribute).official_values_ar[
                        index
                      ],
                    value: i,
                  }) as OptionWithObj,
              ) || []
            }
            style={{
              width: '200px',
            }}
            onChange={(e: any) => {
              if (bulkValueOnChange) {
                bulkValueOnChange(e);
              } else {
                setListings(
                  listings?.map((i: any) => {
                    if (listing.listing_id === i.listing_id) {
                      i[attrKey] = e;
                    }
                    return i;
                  }),
                );
              }
            }}
          >
            {(selectedAttribute as SelectAttribute)?.official_values_en?.map(
              (val, index) => {
                return (
                  <option key={index} value={val}>
                    {val}
                  </option>
                );
              },
            )}
          </AutoComplete>
        </ConfigProvider>
      );
    }
    // Boolean
    if (selectedAttribute?.type === AttributeType.BOOLEAN) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Check
            name="rating-checkbox"
            isSelected={listing[attrKey]} // use your logic for `checked`
            onChange={(e) => handleChange(e, listing)}
            style={{ marginRight: '20px' }} // Style with margin-right, equivalent to `mr="20px"`
          >
            True / False
          </Check>
        </div>
      );
    }
    // Numeric
    return (
      <input
        title="Value"
        type={'number'}
        value={listing[attrKey]}
        style={{
          height: '32px',
          width: '150px',
          margin: '0px',
          minWidth: '0px',
          maxWidth: '100px !important',
          border: '1px solid #D9D9D9',
        }}
        onChange={(e: any) => {
          if (bulkValueOnChange) {
            bulkValueOnChange(e.target.value);
          } else {
            setListings(
              listings?.map((i: any) => {
                if (listing.listing_id === i.listing_id) {
                  i[attrKey] = e.target.value;
                }
                return i;
              }),
            );
          }
        }}
      />
    );
  };

  const transformedColumns = [
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Check
            name="update-all-checkbox"
            isSelected={updateSelectedListings.length === listings.length}
            onValueChange={(e) => {
              if (e) {
                setUpdateSelectedListings([...listings]);
              } else {
                setUpdateSelectedListings([]);
              }
            }}
          >
            Update?
          </Check>
        </div>
      ),
      key: 'update',
      align: 'center' as AlignType,
      render: (row: SolrResponseDoc) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Check
            name="rating-checkbox"
            isSelected={updateSelectedListings.includes(row)}
            onChange={() => {
              if (updateSelectedListings.includes(row)) {
                setUpdateSelectedListings(
                  updateSelectedListings.filter((i) => i !== row),
                );
              } else {
                setUpdateSelectedListings([...updateSelectedListings, row]);
              }
            }}
          ></Check>
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Check
            name="bulk-all-checkbox"
            isSelected={bulkSelectedListings.length === listings.length}
            onChange={(e) => {
              if (e.target.checked) {
                setBulkSelectedListings([...listings]);
              } else {
                setBulkSelectedListings([]);
              }
            }}
          >
            Bulk
          </Check>
        </div>
      ),
      key: 'bulk',
      align: 'center' as AlignType,
      render: (row: SolrResponseDoc) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Check
            name="rating-checkbox"
            isSelected={bulkSelectedListings.includes(row)}
            onChange={() => {
              if (bulkSelectedListings.includes(row)) {
                setBulkSelectedListings(
                  bulkSelectedListings.filter((i) => i !== row),
                );
              } else {
                setBulkSelectedListings([...bulkSelectedListings, row]);
              }
            }}
          ></Check>
        </div>
      ),
    },
    {
      title: 'Listing ID',
      dataIndex: 'listing_id',
      key: 'listing_id',
      align: 'center' as AlignType,
      sorter: (a: SolrResponseDoc, b: SolrResponseDoc) =>
        a.listing_id - b.listing_id,
      render: (listing_id: number) =>
        listing_id ? (
          <Link
            to={`/products/listing-details/${listing_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'green',
              textDecoration: 'underline',
              fontFamily: Fonts.primary,
              fontWeight: 600,
              fontSize: '13px',
            }}
          >
            #{listing_id}
          </Link>
        ) : (
          <span>Invalid Listing ID</span>
        ),
    },
    {
      title: 'Listing Name',
      dataIndex: 'name_en',
      key: 'name_en',
      align: 'center' as AlignType,
      ...getColumnSearchProps('name_en'),
    },
    {
      title: 'Current Value',
      key: 'current_value',
      align: 'center' as AlignType,
      render: (row: SolrResponseDoc) => {
        if (!selectedAttribute) {
          return <>SELECT ATTR FIRST</>;
        }
        return renderTypeFormat(row);
      },
    },
    {
      title: (
        <div
          className="inputs"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <p
            style={{
              fontFamily: Fonts.secondary,
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '10px',
              textTransform: 'uppercase',
              height: '10px',
              color: 'rgba(0, 39, 51, 0.5)',
            }}
          >
            Null Only({listingsWithNull.length})
          </p>
          <Check
            name="rating-checkbox"
            isSelected={showNullOnly}
            onChange={() => setShowNullOnly(!showNullOnly)}
          ></Check>
        </div>
      ),
      key: 'null_only',
      width: '100px',
      align: 'center' as AlignType,
    },
  ];

  function applyBulkEdit() {
    const updatedListings = listings?.map((i: any) => {
      if (bulkSelectedListings.includes(i)) {
        return { ...i, [attrKey]: bulkValue };
      }
      return i;
    });
    setListings(updatedListings);
  }

  async function submit() {
    if (!selectedAttribute) {
      toast.warn('Please select an attribute first');
      return;
    }
    if (!updateSelectedListings.length) {
      toast.warn('Please select listings to update');
      return;
    }
    await CategoriesRepository.bulkEditAttributes(
      listings
        .filter((i: any) => updateSelectedListings.includes(i))
        ?.map((i: any) => {
          return {
            listing_id: i.listing_id,
            new_value_en:
              selectedAttribute.type === AttributeType.NUMERIC
                ? parseFloat(i[attrKey])
                : selectedAttribute.type === AttributeType.BOOLEAN
                  ? i[attrKey] === 'true' || i[attrKey] === true
                  : i[attrKey],
          };
        }),
      selectedAttribute?.id,
      selectedAttribute?.type,
    );
  }
  const mappedOptions = selectedCategoryAttributesOptions.map(
    (option: OptionWithObj) => ({
      label: String(option.label.name_en),
      value: option.value,
    }),
  );

  const [inputValue, setInputValue] = useState('');

  const handleChange = (val: any) => {
    setInputValue(val);
    const selectedOption = selectedCategoryAttributesOptions.find(
      (option) => option.value === val,
    );
    if (selectedOption) {
      setSelectedAttribute(selectedOption.label);
      setAttrKey(buildAttributeKey(selectedOption.label));
      setListingsWithNull(listings.filter((i) => !i[attrKey]));
    }
  };

  const buildAttributeKey = (label: any) => {
    return label;
  };

  const options = selectedCategoryAttributesOptions.map((option) => {
    const isMatch =
      option.label.name_en.toUpperCase().includes(inputValue.toUpperCase()) ||
      option.label.name_ar.toUpperCase().includes(inputValue.toUpperCase());
    return {
      label: isMatch ? (
        <div style={{ color: 'green' }}>
          {option.label.name_en} - ${option.label.name_ar}
        </div>
      ) : (
        <div style={{ color: 'black' }}>
          {option.label.name_en} - ${option.label.name_ar}
        </div>
      ),
      value: option.value,
    };
  });

  return (
    <Wrapper>
      {' '}
      <div className="d-flex">
        <img
          src={backarrow}
          onClick={() => navigate(-1)}
          alt="arrow"
          style={{ cursor: 'pointer', marginRight: '10px' }}
        />

        <MainHeading text={'Review Attributes Values'} />
      </div>
      <div className="body">
        <div className="description">
          <SubHeading
            text={`We have noticed that ${listingsWithNull.length} items are missing the newly added ${selectedAttribute?.name_en} attribute, please add attribute values to them before continuing.`}
          />
        </div>
        <div>
          <Row>
            <Col lg={12} style={{ gap: '2px', marginBottom: '5px' }}>
              <Heading text={`Missing Values Table for Attribute:`} />
            </Col>
          </Row>
          <Row
            className="justify-center"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Col
              lg={4}
              className="justify-center align-middle text-center"
              style={{ gap: '5px' }}
            >
              {/** modal */}
              <AntdButton
                color="primary"
                variant="dashed"
                onClick={() => {
                  setShowCategoryModal(true);
                }}
                style={{
                  borderRadius: '10px',
                  fontSize: '15px',
                }}
              >
                <b>Current category is: </b>
                <i style={{ color: 'red' }}>
                  {selectedCategory?.name_en || 'Unselected'}
                </i>
                <u>(Click to change or clear)</u>
              </AntdButton>
              <CategoryModal
                isModalVisible={showCategoryModal}
                hideModal={() => {
                  setShowCategoryModal(false);
                }}
                onConfirm={async (val: AbstractCategoryWithSubcategories) => {
                  if (isNaN(val.id)) return;
                  await SharedRepository.getCategoryBrief(val.id).then(
                    (category: any) => {
                      if (!category) return;
                      setSelectedCategory(category);
                      setSelectedAttribute(undefined);
                      setAttrKey('');
                    },
                  );
                  setShowCategoryModal(false);
                }}
                onClear={() => {
                  setSelectedCategory(undefined);
                }}
              />
            </Col>
            <Col
              lg={4}
              className="justify-center align-middle text-center"
              style={{ gap: '5px' }}
            >
              {selectedCategoryAttributesOptions.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <span className="m-1">
                    <b>Select Attribute:</b>
                  </span>

                  <AutoComplete
                    placeholder="Select Attribute"
                    options={options}
                    onChange={handleChange}
                    onSearch={(val) => setInputValue(val)}
                    style={{ width: '100%' }}
                    className="new_select"
                  />
                </div>
              )}
            </Col>
            <Col
              lg={4}
              className="justify-center align-middle text-center"
              style={{ gap: '5px' }}
            >
              <div
                className="inputs"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {selectedAttribute !== undefined && (
                  <>
                    <div
                      className="value-input"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <span className="m-1">
                        <b>Bulk Value: </b>
                      </span>
                      {renderTypeFormat({ [attrKey]: bulkValue }, setBulkValue)}
                      <div style={{ width: '10px' }} />
                      <AntdButton
                        color="primary"
                        variant="outlined"
                        style={{
                          borderRadius: '10px',
                          fontSize: '10px',
                        }}
                        onClick={applyBulkEdit}
                      >
                        Apply to selected
                      </AntdButton>
                    </div>
                  </>
                )}
              </div>
            </Col>
            <SubHeading
              text={`If many items share the same values you may do bulk update:`}
            />
          </Row>
        </div>

        <div style={{ borderRadius: '20px', margin: '20px 0px 10px 0px' }}>
          <ConfigProvider
            theme={{
              components: {
                Table: {},
              },
            }}
          >
            <Table<SolrResponseDoc>
              dataSource={showNullOnly ? listingsWithNull : listings}
              columns={transformedColumns}
              pagination={{
                showSizeChanger: true,
                position: ['topLeft'],
                defaultCurrent: 1,
                defaultPageSize: 20,
              }}
              footer={() => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <AntdButton
                    onClick={showModal}
                    color="primary"
                    variant="solid"
                    size={'large'}
                  >
                    Submit Changes
                  </AntdButton>

                  <Modal
                    title="Submit"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <p>Are you sure you want to submit the changes?</p>
                  </Modal>
                </div>
              )}
            />
          </ConfigProvider>
        </div>
      </div>
    </Wrapper>
  );
}

export default ReviewAttributes;
