import { AuhtneticationPagesHandler } from 'routes/handlers/authentication_pages_handler';
import { NotFoundPage, Page404 } from 'pages/404/404_page';
import { lazy, Suspense, useEffect, useState } from 'react';
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import SettingsPage from 'pages/settings/settings_page';
import RoutingSchedule from 'pages/logistics/routing_schedule/routing_schedule';

// Auth screens:
import { ManagementFilesRepository } from 'data/repositories/files_repository';
import { S3ApiRepository } from 'silal_app_base_react/src/data/repositories/s3_repository';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import DatabasesPage from 'pages/databases/databases_page';
import StoreProfileParentRouteComponent from 'pages/databases/stores/router';
import { OrdersArchive } from 'silal_app_base_react/src/pages/orders_archive/archived_orders_screen';

// Home screens:
const Dashboard = lazy(
  () => import('pages/home/screens/dashboard/dashboard_screen'),
);
const Profilepage = lazy(
  () => import('pages/home/screens/profile/profile_screen'),
);
const Workingdriverspage = lazy(
  () => import('pages/home/screens/working_drivers/working_drivers_screen'),
);
const Openstores = lazy(
  () => import('pages/home/screens/open_stores/open_stores_screen'),
);
const Overloadedstores = lazy(
  () => import('pages/home/screens/overloaded_stores/overloaded_stores_screen'),
);
const Overloadedregions = lazy(
  () =>
    import('pages/home/screens/overloaded_regions/overloaded_regions_screen'),
);
const Dueorders = lazy(
  () => import('pages/home/screens/due_orders/due_orders_screen'),
);
const Answeredcalls = lazy(
  () => import('pages/home/screens/answered_calls/answered_calls_screen'),
);
const CallerDetails = lazy(
  () => import('pages/home/screens/answered_calls/components/caller_details'),
);
const UsersDatabase = lazy(
  () => import('pages/databases/users/users_database_page'),
);
const CaptainsDatabase = lazy(
  () => import('pages/databases/drivers/drivers_database_screen'),
);
const DriverProfile = lazy(
  () => import('pages/databases/drivers/screens/profile/driver_profile_screen'),
);
const EditDriverProfile = lazy(
  () => import('pages/databases/drivers/flows/edit_driver_profile'),
);
const DriverOrders = lazy(
  () => import('pages/databases/archive/drivers/orders/driver_orders_screen'),
);
const EditDriverOrder = lazy(
  () => import('pages/databases/drivers/flows/edit_driver_orders'),
);
// const DriversTransactions = lazy(
//   () =>
//     import(
//       'pages/databases/drivers/screens/transactions/driver_transactions_screen'
//     ),
// );
// const DriverSchedule = lazy(() =>
//   import('pages/databases/drivers/screens/schedule/driver_schedule_screen'),
// );

// Store tabs:
const StoresDatabaseTab = lazy(
  () => import('pages/databases/stores/stores_database_screen'),
);
const StoreProfileTab = lazy(
  () => import('pages/databases/stores/screens/profile/store_profile_screen'),
);
const StoresOrdersTab = lazy(
  () => import('pages/databases/stores/screens/orders/store_orders_screen'),
);

import { LedgerPage } from 'silal_app_base_react/src/pages/ledger/ledger_screen';
import OrdersRepository from 'data/repositories/orders_repository';
import { actionGetAllOrders } from 'store/orders/orders_actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'data/types/state';
import OrderProfileParentRouteComponent from 'pages/databases/orders/router';
import OrderOverviewTab from 'pages/databases/orders/screens/overview/overview_screen';
import OrderShipmentsTab from 'pages/databases/orders/screens/shipments/shipments_screen';

import RequestsPage from 'pages/inquiries/screens/requests/requests_screen';
import DriverProfileParentRouteComponent from 'pages/databases/drivers/screens/router';
import TransactionsRepository from 'data/repositories/transactions_repository';
import { Transaction } from 'silal_app_base_react/src/data/types/transactions';
import ListingsRepository from 'data/repositories/listings_repository';
import ListingDetails from 'silal_app_base_react/src/pages/listing_profile/listing_profile_page';
import { PaymentReportsArchive } from 'pages/payments/payments_page';
import ReviewAttributes from 'pages/catagoeries/flows/review_attributes';
import OrderLedgerTab from 'pages/databases/orders/screens/ledger/ledger_screen';
import LogisticsParentRouteComponent from 'pages/logistics/router';
import Shifts from 'pages/logistics/shifts/shifts_screen';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import CategoriesRepository from 'data/repositories/categories_repository';
import SilalSuspense from 'silal_app_base_react/src/components/suspense';
import { WorkforceDatabase } from 'pages/settings/screens/workforce/workforce_database';
import TemplatesSearchPage from 'silal_app_base_react/src/pages/templates/templates_search';
import DataEditingRepository from 'data/repositories/data_editing_repository';
import StoreListingsTab from 'pages/databases/stores/screens/listings/store_listings_screen';
import OperatingLocations from 'pages/logistics/operating_locations/operating_locations';
import { ORDER_STATUS_GROUPS } from 'silal_app_base_react/src/data/types/orders';
import CustomersDatabase from 'pages/databases/customers/customers_database_screen';
import CustomerProfileParentRouteComponent from 'pages/databases/customers/screens/router';
import CustomerProfile from 'pages/databases/customers/screens/profile/customer_profile_screen';
import UserProfileParentRouteComponent from 'pages/databases/users/screens/router';
import UserProfile from 'pages/databases/users/screens/profile/user_profile_screen';
import { Helmet } from 'react-helmet-async';

// const StorePromotionsTab = lazy(
//   () => import('pages/databases/stores/screens/promotions/promotions_screen'),
// );
const StoresProductsTab = lazy(
  () => import('pages/databases/archive/stores/products/store_products_screen'),
);
const StoreEditProfile = lazy(
  () => import('pages/databases/stores/flows/seller_edit_profile'),
);
const StoreProductsFolder = lazy(
  () => import('pages/databases/archive/stores/products/components/folder'),
);
const StoreCreateNewItem = lazy(
  () =>
    import(
      'pages/databases/archive/stores/products/components/create_new_item'
    ),
);
// const StoreLedgerTab = lazy(
//   () => import('pages/databases/archive/stores/ledger/store_ledger_screen'),
// );
// const LedgerReport = lazy(
//   () =>
//     import('pages/databases/archive/stores/ledger/components/ledger_report'),
// );
const LedgerAction = lazy(
  () =>
    import(
      'pages/databases/archive/stores/transactions/store_transactions_screen'
    ),
);

const PromotionsPage = lazy(() => import('pages/promotions/promotions_page'));
const InquiriesPage = lazy(() => import('pages/inquiries/inquiries_page'));
const RequestsFoldersScreen = lazy(
  () => import('pages/inquiries/screens/requests_folders_screen'),
);
const DefaultStartingView = lazy(
  () => import('pages/inquiries/screens/requests/components/request_base_view'),
);
const CategoriesPage = lazy(() => import('pages/catagoeries/categories_page'));
const AddNewCategory = lazy(
  () => import('pages/catagoeries/flows/add_new_category'),
);
const CategoryProfile = lazy(
  () => import('pages/catagoeries/screens/category_profile'),
);
const TemplatesPage = lazy(() => import('pages/templates/templates_page'));
// const CategoryTemplate = lazy(
//   () => import('pages/templates/components/category_templates'),
// );
const CreateItem = lazy(() => import('pages/templates/create_item'));
const AIListingSearch = lazy(
  () => import('silal_app_base_react/src/pages/templates/ai_listing_search'),
);
const ImageLibraryPage = lazy(
  () =>
    import('silal_app_base_react/src/pages/image_library/image_library_page'),
);
const BannersPage = lazy(() => import('pages/banners/banners_page'));

const Corderedit = lazy(
  () => import('pages/databases/orders/components/edit_order'),
);

const UserAuthenticatedViewRouter = () => {
  const dispatch = useDispatch();
  const lastFetchedOrders = useSelector(
    (state: RootState) => state.Orders.lastFetched,
  );
  const lastFetchedRequests = useSelector(
    (state: RootState) => state.Requests.lastFetched,
  );

  const { userManagementAvailableRoutes } = useSelector(
    (state: RootState) => state.Auth,
  );

  useEffect(() => {
    dispatch(actionGetCategoriesMinimalList());

    // if (
    //   userManagementAvailableRoutes?.databases &&
    //   (!lastFetchedOrders || shouldRefreshCache(GET_ORDERS, lastFetchedOrders))
    // ) {
    //   dispatch(actionGetAllOrders([]));
    // }

    // if (
    //   userManagementAvailableRoutes?.inquiries &&
    //   (!lastFetchedRequests ||
    //     shouldRefreshCache(GET_REQUESTS, lastFetchedRequests))
    // )
    //   dispatch(actionGetRequests());
  }, [userManagementAvailableRoutes]);
  return (
    <AuhtneticationPagesHandler>
      <Routes>
        {/* Home */}
        <Route
          path="/"
          element={
            <Suspense fallback={<SilalSuspense />}>
              <Outlet />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="profile"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Profilepage />
              </Suspense>
            }
          />
          <Route
            path="working-drivers"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Workingdriverspage />
              </Suspense>
            }
          />
          <Route
            path="openstores"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Openstores />
              </Suspense>
            }
          />
          <Route
            path="overloadedstores"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Overloadedstores />
              </Suspense>
            }
          />
          <Route
            path="overloadedregions"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Overloadedregions />
              </Suspense>
            }
          />
          <Route
            path="dueorders"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Dueorders />
              </Suspense>
            }
          />
          <Route
            path="answeredcalls"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Answeredcalls />
              </Suspense>
            }
          >
            <Route
              path=":callerId"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <CallerDetails />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<SilalSuspense />}>
              <Outlet />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="profile"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Profilepage />
              </Suspense>
            }
          />
          <Route
            path="working-drivers"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Workingdriverspage />
              </Suspense>
            }
          />
          <Route
            path="openstores"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Openstores />
              </Suspense>
            }
          />
          <Route
            path="overloadedstores"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Overloadedstores />
              </Suspense>
            }
          />
          <Route
            path="overloadedregions"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Overloadedregions />
              </Suspense>
            }
          />
          <Route
            path="dueorders"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Dueorders />
              </Suspense>
            }
          />
          <Route
            path="answeredcalls"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Answeredcalls />
              </Suspense>
            }
          >
            <Route
              path=":callerId"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <CallerDetails />
                </Suspense>
              }
            />
          </Route>
        </Route>

        {/* Databases */}
        {userManagementAvailableRoutes?.databases && (
          <Route
            path="/databases"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <DatabasesPage />
                </Suspense>
              }
            />
            {/* Users DB*/}
            <Route
              path="users"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <UsersDatabase />
                  </Suspense>
                }
              />

              <Route
                path=":userId"
                element={<UserProfileParentRouteComponent />}
              >
                <Route
                  path="profile"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <UserProfile />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            {/* Customers DB*/}
            <Route
              path="customers"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <CustomersDatabase />
                  </Suspense>
                }
              />

              <Route
                path=":customerId"
                element={<CustomerProfileParentRouteComponent />}
              >
                <Route
                  path="profile"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <CustomerProfile />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            {/* Stores DB*/}
            <Route
              path="stores"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <StoresDatabaseTab />
                  </Suspense>
                }
              />
              <Route
                path=":storeId"
                element={<StoreProfileParentRouteComponent />}
              >
                <Route
                  path="profile"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <StoreProfileTab />
                    </Suspense>
                  }
                />
                <Route
                  path="edit-profile"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <StoreEditProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="products"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <StoresProductsTab />
                    </Suspense>
                  }
                />
                <Route
                  path="products/:productid"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <Outlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<SilalSuspense />}>
                        <StoreProductsFolder />
                      </Suspense>
                    }
                  />
                  <Route
                    path="createnewitem"
                    element={
                      <Suspense fallback={<SilalSuspense />}>
                        <StoreCreateNewItem />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  path="current-orders"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <StoresOrdersTab />
                    </Suspense>
                  }
                />
                <Route
                  path="editorder"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <Corderedit />
                    </Suspense>
                  }
                />
                <Route
                  path="ledger"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <LedgerPageWrapper isSellerTransactions />
                    </Suspense>
                  }
                />
                <Route
                  path="listings"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <StoreListingsTab />
                    </Suspense>
                  }
                />
                {/* <Route
                path="payments-report"
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <StoreLedgerTab />
                    </Suspense>
                  }
                />
                <Route
                  path="ledger-report"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <LedgerReport />
                    </Suspense>
                  }
                />
              </Route> */}
                <Route
                  path="ledger-action"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <LedgerAction />
                    </Suspense>
                  }
                />
                {/* <Route
              path="promotion"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <StorePromotionsTab />
                </Suspense>
              }
            /> */}
              </Route>
            </Route>

            {/* Captains DB*/}
            <Route
              path="drivers"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <CaptainsDatabase />
                  </Suspense>
                }
              />
              <Route
                path=":driverId"
                element={<DriverProfileParentRouteComponent />}
              >
                <Route
                  path="profile"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <DriverProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="orders"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <DriverOrders />
                    </Suspense>
                  }
                />
                <Route
                  path="edit-profile"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <EditDriverProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="edit-order/:order_id"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <EditDriverOrder />
                    </Suspense>
                  }
                />
                <Route
                  path="ledger"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <LedgerPageWrapper isSellerTransactions={false} />
                    </Suspense>
                  }
                />
                {/* <Route
              path="schedules"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <DriverSchedule />
                </Suspense>
              }
            /> */}
              </Route>
            </Route>

            {/* Orders DB*/}
            <Route
              path="orders-archive"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <OrdersArchivePageWrapper />
                  </Suspense>
                }
              />
              <Route
                path=":orderId"
                element={<OrderProfileParentRouteComponent />}
              >
                <Route
                  index
                  path="overview"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <OrderOverviewTab />
                    </Suspense>
                  }
                />
                <Route
                  path="shipments"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <OrderShipmentsTab />
                    </Suspense>
                  }
                />
                <Route
                  path="ledger"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <OrderLedgerTab />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
        )}

        {/* Coupons */}
        {userManagementAvailableRoutes?.coupons && (
          <Route
            path="/promotions"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <PromotionsPage />
                </Suspense>
              }
            />
          </Route>
        )}

        {/* Payment Reports */}
        {userManagementAvailableRoutes?.payments && (
          <Route path="/payment-reports">
            <Route index element={<PaymentReportsArchive />} />
            <Route path="drivers" element={<PaymentReportsArchive />} />
            <Route path="stores" element={<PaymentReportsArchive />} />
          </Route>
        )}

        {/* Inquiries */}
        {userManagementAvailableRoutes?.inquiries && (
          <Route
            path="inquiries"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <RequestsFoldersScreen />
                </Suspense>
              }
            />
            <Route
              path="requests"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <Outlet />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <RequestsFoldersScreen />
                  </Suspense>
                }
              />
              <Route
                path="businesses"
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <RequestsPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":requestId"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <DefaultStartingView />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="new-listings"
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <RequestsPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":requestId"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <DefaultStartingView />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="update-listings"
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <RequestsPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":requestId"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <DefaultStartingView />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="drivers"
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <RequestsPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":requestId"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <DefaultStartingView />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="promotions"
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <RequestsPage />
                    </Suspense>
                  }
                />
                <Route
                  path=":requestId"
                  element={
                    <Suspense fallback={<SilalSuspense />}>
                      <DefaultStartingView />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
        )}

        {/* Categories */}
        {userManagementAvailableRoutes?.categories && (
          <Route
            path="/categories"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <CategoriesPage />
                </Suspense>
              }
            />
            <Route
              path="category-profile/:categoryId"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <CategoryProfile />
                </Suspense>
              }
            />
            <Route
              path="add-new"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <AddNewCategory />
                </Suspense>
              }
            />
            <Route
              path="review-attributes"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <ReviewAttributes />
                </Suspense>
              }
            >
              <Route
                path=":categoryId"
                element={
                  <Suspense fallback={<SilalSuspense />}>
                    <ReviewAttributes />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        )}

        {/* Products */}
        {userManagementAvailableRoutes?.products && (
          <Route path="/products">
            <Route
              path="listing-details/:listingId"
              element={<ListingPageWrapper />}
            />
          </Route>
        )}

        {/* Templates */}
        {userManagementAvailableRoutes?.templates && (
          <Route
            path="/templates"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <TemplatesSearchPage
                    s3Repository={S3ApiRepository.getInstance(
                      managementAxiosInstance,
                    )}
                    filesRepository={ManagementFilesRepository}
                  />
                </Suspense>
              }
            />
            <Route
              path="search"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <TemplatesSearchPage
                    s3Repository={S3ApiRepository.getInstance(
                      managementAxiosInstance,
                    )}
                    filesRepository={ManagementFilesRepository}
                  />
                </Suspense>
              }
            />
            <Route
              path="ai_listing"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <AIListingSearch
                    filesRepository={ManagementFilesRepository}
                    s3Repository={S3ApiRepository.getInstance(
                      managementAxiosInstance,
                    )}
                  />
                </Suspense>
              }
            />
            <Route
              path="database"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <TemplatesPage />
                </Suspense>
              }
            />
            <Route
              path="new"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <CreateItem />
                </Suspense>
              }
            />
            {/* <Route
          path="show-templates"
          element={
            <Suspense fallback={<SilalSuspense />}>
              <CategoryTemplate />
            </Suspense>
          }
        /> //TODO why does this route even exist? */}
          </Route>
        )}

        {/* Image Library */}
        {userManagementAvailableRoutes?.image_library && (
          <Route
            path="/image-library"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <ImageLibraryPage
                    is_private={false}
                    filesRepository={ManagementFilesRepository}
                    s3Repository={S3ApiRepository.getInstance(
                      managementAxiosInstance,
                    )} // TODO move to a global context or remove
                  />
                </Suspense>
              }
            />
          </Route>
        )}

        {/* Banners */}
        {userManagementAvailableRoutes?.banners && (
          <Route
            path="/banners"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <BannersPage />
                </Suspense>
              }
            />
          </Route>
        )}

        {/* Logistics */}
        {userManagementAvailableRoutes?.logistics && (
          <Route
            path="/logistics"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <LogisticsParentRouteComponent />
              </Suspense>
            }
          >
            {' '}
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <RoutingSchedule />
                </Suspense>
              }
            />{' '}
            <Route
              path="schedule"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <RoutingSchedule />
                </Suspense>
              }
            />{' '}
            <Route
              path="shifts"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <Shifts />
                </Suspense>
              }
            />{' '}
            <Route
              path="shifts/:shiftId" // Subroute with dynamic shiftId parameter
              element={<Shifts />}
            />{' '}
            <Route
              path="operating-locations"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <OperatingLocations />
                </Suspense>
              }
            />
          </Route>
        )}

        {/* Settings */}
        {userManagementAvailableRoutes?.settings && (
          <Route
            path="/settings"
            element={
              <Suspense fallback={<SilalSuspense />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <SettingsPage />
                </Suspense>
              }
            />
            <Route
              path="workforce"
              element={
                <Suspense fallback={<SilalSuspense />}>
                  <WorkforceDatabase />
                </Suspense>
              }
            />
          </Route>
        )}

        {/* 404 */}
        <Route path="/404" element={<Page404 path="/dashboard" time={5} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuhtneticationPagesHandler>
  );
};

const OrdersArchivePageWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orders } = useSelector((state: RootState) => state.Orders);

  useEffect(() => {
    dispatch(actionGetAllOrders([]));
  }, []);

  const openOrderDetailsFunction = async (orderId: number) => {
    navigate(`/databases/orders-archive/${orderId}/overview`);
  };

  const openListingDetailsFunction = async (listingId: number) => {
    navigate(`/products/listing-details/${listingId}`);
  };

  return (
    <>
      <Helmet>
        <title>Orders | Silal Management</title>
      </Helmet>
      <h2
        className="poppins-font"
        style={{
          fontSize: '25px',
          fontWeight: 700,
          margin: '1rem 0rem 0rem 1rem',
        }}
      >
        Orders database
      </h2>
      <OrdersArchive
        orders={orders}
        openOrderDetailsFunction={openOrderDetailsFunction}
        openListingDetailsFunction={openListingDetailsFunction}
        ordersRepository={OrdersRepository}
        isManagement={true}
        refreshActiveOrders={() => {
          dispatch(actionGetAllOrders(Array.from(ORDER_STATUS_GROUPS.active)));
        }}
        refreshOrders={() => {
          dispatch(actionGetAllOrders([]));
        }}
      />
    </>
  );
};

const LedgerPageWrapper = ({
  isSellerTransactions,
}: {
  isSellerTransactions: boolean;
}) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { storeId } = useParams();
  const { driverId } = useParams();
  useEffect(() => {
    const fetchTransactions = async () => {
      if (isSellerTransactions) {
        TransactionsRepository.getStoreLedger(storeId!).then((res) => {
          if (!res) return;
          setTransactions(res);
        });
      } else {
        TransactionsRepository.getDriverLedger(driverId!).then((res) => {
          if (!res) return;
          setTransactions(res);
        });
      }
    };
    fetchTransactions();
  }, []);

  return (
    <LedgerPage
      transactions={transactions}
      isSellerTransactions={isSellerTransactions}
    />
  );
};

const ListingPageWrapper = () => {
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );

  return (
    <ListingDetails
      ListingsRepository={ListingsRepository}
      categoriesRepository={CategoriesRepository}
      dataEditingRepository={DataEditingRepository}
      categoryNamesMap={minimalCategoriesList?.reduce((acc: any, category) => {
        acc[category.id] = category.name_en + ' | ' + category.name_ar;
        return acc;
      }, {})}
    />
  );
};

export default UserAuthenticatedViewRouter;
