import { NavLink } from 'react-router-dom';
import Wrapper from './top_row.styled';
import { CustomerUserInfo } from 'silal_app_base_react/src/data/types/users';
import { Helmet } from 'react-helmet-async';

function CustomerProfileToprow({ customer }: { customer: CustomerUserInfo }) {
  const CustomerProfileNavData = [
    {
      id: 'f5343',
      name: 'Profile',
      pathName: 'profile',
    },
    {
      id: '9jla',
      name: 'Orders',
      pathName: '/databases/orders-archive',
    },
  ];
  return (
    <Wrapper>
      <Helmet>
        <title>
          {'Customer #' +
            customer.id +
            ': ' +
            customer.first_name +
            ' ' +
            customer.last_name}
          | Silal Management
        </title>
      </Helmet>
      <div>
        <div className="row">
          <div className="top-row">
            <div className="customer-name-id poppins-font">
              <h2>{customer.first_name + ' ' + customer.last_name}</h2>
            </div>
          </div>
          <div className="top-row-buttons poppins-font">
            {CustomerProfileNavData.map((nav) => (
              <NavLink
                key={nav.id}
                to={nav.pathName}
                className={(info) => (info.isActive ? 'active' : '')}
                state={{
                  customer: customer,
                  filters: {
                    customers: [customer.id],
                  },
                }}
                end
              >
                {nav.name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default CustomerProfileToprow;
