// import GetProgressMonitor from "./Components/Categories/GetProgressMonitorBar";
import AppRouter from 'routes/router';
import 'styles/index.css';
import { store, persistor } from 'store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { ENVIROMENT_TYPE } from 'silal_app_base_react/src/config/constants';
import { useEffect } from 'react';
import { getCurrentEnviromentType } from 'silal_app_base_react/src/utils/axios/configutation';
import { NextUIProvider } from '@nextui-org/react';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';

const enableSentry = !window.location.hostname.includes('localhost');

Sentry.init({
  dsn: 'https://d38a267b0aa296bcc090e15ae400adc7@o4507991445667840.ingest.de.sentry.io/4507996002189392',
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.0001,
  environment: getCurrentEnviromentType(),
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/silal\.app\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: false,
  enabled: enableSentry,
});

function App() {
  useEffect(() => {
    if (getCurrentEnviromentType() === ENVIROMENT_TYPE.DEVELOPMENT) {
      if (!document.title.includes('Development')) {
        document.title += ' Development';
      }
    }

    if (window.location.hostname.includes('localhost')) {
      if (
        getCurrentEnviromentType() === ENVIROMENT_TYPE.PRODUCTION &&
        !document.title.includes('Production')
      ) {
        document.title += ' Production';
      }
      if (!document.title.includes('Local')) {
        document.title += ' Local';
      }
    }
  }, []);
  return (
    <HelmetProvider>
      <ErrorBoundary
        fallback={({ error, componentStack, resetError }) => {
          if (enableSentry) Sentry.captureException(error);
          return (
            <div>
              <h1>Something went wrong</h1>
              <pre>{(error as any)?.message}</pre>
              <pre>{componentStack}</pre>
              <button onClick={resetError}>Try again</button>
            </div>
          );
        }}
      >
        <NextUIProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: Colors.greenMain,
              },
            }}
          >
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ToastContainer
                  position="top-center"
                  autoClose={3500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  // pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <AppRouter />
                {/* <GetProgressMonitor /> //TODO disabled due to error in loading enable it back after fixing getProgressMonitor */}
              </PersistGate>
            </Provider>
          </ConfigProvider>
        </NextUIProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;

// Used for react-query, will be enabled if needed
// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       retry(failureCount, error) {
//         if (failureCount < 1) {
//           return true;
//         }
//         const { code, message } = ErrorHandler(error);
//         toast.error(
//           `Oops ${getCurrentEnviromentType() === ENVIROMENT_TYPE.DEVELOPMENT ? 'APP_HANDLER ' : ''}! ${message}. Error code ${code}`,
//         );
//         return false;
//       },
//       throwOnError: false,
//     },
//   },
// });
