import {
  RefundPayload,
  RefundType,
} from 'pages/databases/orders/functions/refunds_functions';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { ORDERS_MESASGES } from 'silal_app_base_react/src/data/constants/messages';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import { Package } from 'silal_app_base_react/src/data/types/shipping';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';

export class ManagementOrdersApiRepository extends AuthenticatedApiRepository {
  private static instance: ManagementOrdersApiRepository | null = null;
  private constructor(axiosInstance: any) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: any): ManagementOrdersApiRepository {
    if (!ManagementOrdersApiRepository.instance) {
      ManagementOrdersApiRepository.instance =
        new ManagementOrdersApiRepository(axiosInstance);
    }
    return ManagementOrdersApiRepository.instance;
  }

  getOrderDataByID = async (order_id: number | string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/orders/${order_id}/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
        }),
        {
          noPendingMsg: true,
          noSuccessMsg: true,
          customErrorMsg: 'No order found with this ID.',
        },
      );
      return res.data.order as OrderDetails;
    } catch (error: any) {
      return false; // Add this line to return undefined in case of an error
    }
  };
  // Edit order notes
  editOrderNotes = async (order_id: number, note: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/orders/${order_id}/edit_note/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
          data: { order_management_notes: note },
        }),
        {
          noPendingMsg: false,
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: ORDERS_MESASGES.OrderNoteChanged,
        },
      );
      return res.status === 202;
    } catch (error: any) {
      return false; // Add this line to return undefined in case of an error
    }
  };

  // Cancel Order Endpoint
  cancelOrder = async (orderId: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/orders/${orderId}/cancel/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
        }),
        {
          noSuccessMsg: false,
          customSuccessMsg: 'Order has been cancelled successfully',
          noFailureMsg: false,
          customPendingMsg: 'Cancelling order...',
          noPendingMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Collect Return From Customer Endpoint
  collectReturnFromCustomer = async (orderId: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/orders/${orderId}/collect_return_from_customer/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: true,
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Initiate Self-Return Endpoint
  initiateSelfReturn = async (orderId: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/orders/${orderId}/initiate_self_return/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Mark Order as Inspected Endpoint
  markOrderAsInspected = async (orderId: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/orders/${orderId}/mark_return_inspected/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Reject Order Return  At Silal Endpoint
  rejectOrderReturnAtSilal = async (orderId: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/orders/${orderId}/mark_return_rejected/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  returnUndeliveredOrder = async (orderId: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/orders/${orderId}/return_undelivered_order_and_refund/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: true,
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Schedule Return To Store Endpoint
  scheduleReturnToStore = async ({
    orderId,
    shift_id,
    packages,
  }: {
    orderId: number;
    shift_id: number;
    packages: Package[];
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/orders/${orderId}/schedule_return_to_store/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
            data: {
              shift_id,
              packages,
            },
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return (res.status === 202 && res.data) || false;
    } catch (error: any) {
      return false;
    }
  };

  // Schedule Silal-Return Endpoint
  scheduleSilalReturn = async (
    orderId: number,
    shift_id: number,
    packages: Package[],
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/orders/${orderId}/schedule_return_pickup_from_customer/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: true,
            data: {
              shift_id,
              packages,
            },
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Update Shipment Dropoff Shift Endpoint
  updateShipmentDropoffShift = async (
    orderId: number,
    new_dropoff_shift_id: number,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/orders/${orderId}/reschedule_failed_dropoff_to_customer/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
            data: {
              new_dropoff_shift_id,
            },
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Update Pickup And/Or Drop-off Shift Endpoint
  updateOrderPickupAndDropoffShifts = async (
    orderId: number,
    new_pickup_shift_id: number,
    new_dropoff_shift_id?: number,
    packages?: Package[],
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/orders/${orderId}/reschedule_failed_pickup_from_store/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
            data: {
              new_pickup_shift_id,
              new_dropoff_shift_id,
              packages,
            },
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Refund Order Endpoint
  refundOrder = async (orderId: number, refundObject: RefundPayload) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/orders/${orderId}/refund/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
          data: {
            ...refundObject,
            refund_as_balance_credit:
              RefundType.balance_credit ===
              refundObject.refund_as_balance_credit,
            amount_to_deduct_from_store_debt:
              refundObject.amount_to_deduct_from_store_debt.toString(),
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };
}

const ManagementOrdersRepository = ManagementOrdersApiRepository.getInstance(
  managementAxiosInstance,
);

export default ManagementOrdersRepository;
