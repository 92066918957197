import Heading from 'components/headings/main_heading';
import { Button, Input } from '@nextui-org/react';
import DeliveryRepository from 'data/repositories/delivery_repository';
import { useEffect, useState } from 'react';
import { Shipment } from 'silal_app_base_react/src/data/types/shipping';
import { ShipmentCard } from '../components/shipment_card';
import { notFoundIllustration_path } from 'silal_app_base_react/src/assets';
import { Colors } from 'silal_app_base_react/src/config/theme';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'data/types/state';
import { RegisteredCarriersChips } from '../components/shift_card';
import { Shift } from 'silal_app_base_react/src/data/types/shifts';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

export default function Shifts() {
  const pageParams = useParams();
  const { shifts } = useSelector((state: RootState) => state.Shifts);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [shiftId, setShiftId] = useState('');
  const [currentShift, setCurrentShift] = useState<Shift>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pageParams?.shiftId) {
      setShiftId(pageParams.shiftId);
      getShiftShipments(pageParams?.shiftId);
    }
  }, []);

  async function getShiftShipments(id: string) {
    setLoading(true);
    if (!id) {
      toast.warning('Please enter a shift ID');
      setLoading(false);
      return;
    }
    await DeliveryRepository.listShipmentsInShift(id).then((res) => {
      if (!res) return;
      setShipments(res);
      setCurrentShift(shifts.find((shift) => shift.id.toString() === id));
      // update link without refreshiing the page
      window.history.pushState({}, '', `/logistics/shifts/${id}`);
    });
    setLoading(false);
  }

  return (
    <div
      style={{
        borderRadius: '20px',
        backgroundColor: 'white',
        padding: '20px',
      }}
    >
      <Helmet>
        <title>
          Shift #{pageParams?.shiftId ? pageParams.shiftId : ''} | Silal
          Management
        </title>
      </Helmet>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Heading
            text={
              shipments.length !== 0
                ? `Found ${shipments.length} in shift #${shiftId}`
                : `Search for shipments in a shift`
            }
            className="mt-4"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 'var(--lg)',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            className="my-2"
          >
            <Input
              placeholder="Enter shift ID"
              label="Shift ID:"
              labelPlacement="outside-left"
              value={shiftId}
              onChange={(e) => setShiftId(e.target.value)}
              isInvalid={shiftId === '' || isNaN(Number(shiftId))}
            />
            <Button
              color="success"
              variant="ghost"
              onClick={() => getShiftShipments(shiftId)}
            >
              Get Shipments
            </Button>
          </div>
        </div>
        <p className="text-sm text-gray-500">
          Start Time:{' '}
          {formateServerDateToLocaleDate(currentShift?.start_datetime)}
        </p>
        <p className="text-sm text-gray-500">
          End Time: {formateServerDateToLocaleDate(currentShift?.end_datetime)}
        </p>
        {currentShift && (
          <RegisteredCarriersChips
            shift={currentShift}
            chipDisabled={
              Date.now() > new Date(currentShift.end_datetime).getTime()
            }
            onDeleteCallback={async () =>
              await getShiftShipments(currentShift.id.toString())
            }
          />
        )}
      </>

      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(70vh - 80px)',
          }}
        >
          <Spinner />
        </div>
      )}
      {!loading &&
        (shipments.length == 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(70vh - 80px)',
            }}
          >
            <img width="300px" height="300px" src={notFoundIllustration_path} />
            <p
              style={{
                color: Colors.grey,
                marginTop: '20px',
                fontSize: '32px',
              }}
            >
              {!pageParams?.shiftId
                ? 'Enter a shift ID to get shipments'
                : 'No shipments found in this shift'}
            </p>
          </div>
        ) : (
          <div>
            {shipments.map((shipment, i) => (
              <ShipmentCard shipment={shipment} key={i} />
            ))}
          </div>
        ))}
    </div>
  );
}
