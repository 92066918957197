import { useEffect, useState } from 'react';
import Wrapper from './sidebar.styled';
import { Link, useNavigate } from 'react-router-dom';

import Logouticon from 'assets/images/sidenavicons/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fullLogout } from 'routes/functions/routes_functions';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import DeliveryRepository from 'data/repositories/delivery_repository';
import { toast } from 'react-toastify';
import { PopupWithOneInput } from 'silal_app_base_react/src/components/popups';
import { FaBarcode } from 'react-icons/fa6';
import { FloatButton } from 'antd';
import { BsLightning } from 'react-icons/bs';
import {
  LuMessageSquare,
  LuPackageSearch,
  LuShoppingBag,
} from 'react-icons/lu';
import { TiShoppingCart } from 'react-icons/ti';
import ManagementOrdersRepository from 'data/repositories/management_orders_repository';
import { RootState } from 'data/types/state';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { usePersistState } from 'silal_app_base_react/src/hooks/usePersistState';

const { Sider } = Layout;
type MenuItem = {
  id: string;
  icon: any;
  title: string;
  path: string;
  dropdown?: {
    id: string;
    dtitle: string;
    path: string;
  }[];
};

function Sidenav({ menu }: { menu: MenuItem[] }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userManagementAvailableRoutes } = useSelector(
    (state: RootState) => state.Auth,
  );
  const [selected, setSelected] = usePersistState(menu[0].id, 'selectedMenu');
  const [collapsed, setCollapsed] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);

  const [packageInputModal, setPackageInputModal] = useState(false);
  const [generateBarcodesModal, setGenerateBarcodesModal] = useState(false);
  const [goToOrderModal, setGoToOrderModal] = useState(false);
  const [goToListingModal, setGoToListingModal] = useState(false);
  const [goToRequestModal, setGoToRequestModal] = useState(false);

  const iconStyle = {
    strokeWidth: 2,
    size: 30,
    cursor: 'pointer', // Optional: Indicate hoverability with cursor
    color: Colors.grey,
  };

  useEffect(() => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname.includes(menu[0].path)
    ) {
      setSelected(menu[0].id);
    }
  }, []);

  type MenuItem = Required<MenuProps>['items'][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }
  const items: MenuItem[] = menu.map((item) =>
    getItem(
      item.title,
      item.id,
      <img src={item.icon} alt="icon" />,
      item.dropdown?.map((dropItem) => getItem(dropItem.dtitle, dropItem.id)),
    ),
  );

  const go = (e: any) => {
    for (const item of menu) {
      if (item && item.id === e.key) {
        navigate(item.path);
        setSelected(e.key);
        return;
      }
    }

    for (const item of menu) {
      if (item && item.dropdown) {
        for (const dropItem of item.dropdown) {
          if (dropItem.id === e.key) {
            navigate(`${item.path}/${dropItem.path}`);
            setSelected(e.key);
            return;
          }
        }
      }
    }
  };

  return (
    <Wrapper>
      <PopupWithOneInput
        open={generateBarcodesModal}
        onButtonOneClick={() => {
          setGenerateBarcodesModal(false);
          return;
        }}
        headerTitle="Generate Barcodes"
        inputOneLabel="# of Barcodes to Generate"
        inputOnePlaceholder="20"
        inputOneValidator={(e: string) =>
          !e || isNaN(parseInt(e)) || parseInt(e) < 1
        }
        buttonTwoText="Generate"
        onButtonTwoClick={async (count: string) => {
          console.log(count);
          if (!count || isNaN(parseInt(count)) || parseInt(count) < 1) {
            toast.warn('Please put a positive number');
            return;
          }
          await DeliveryRepository.generateNewCodes(count).then((res) => {
            if (!res) {
              return;
            }
            setGenerateBarcodesModal(false);
          });
        }}
      />
      <PopupWithOneInput
        open={packageInputModal}
        onButtonOneClick={() => {
          setPackageInputModal(false);
          return;
        }}
        headerTitle="Get Shipment By Code"
        inputOneLabel="Shipment Tracking Code"
        inputOnePlaceholder="Enter the shipment tracking code"
        inputOneValidator={(e: string) => !e}
        buttonTwoText="Get Shipment"
        onButtonTwoClick={async (trackingCode: string) => {
          if (!trackingCode) return;
          await DeliveryRepository.getShipmentByTrackingCode(trackingCode).then(
            (res) => {
              if (!res || !res.shipments || !res.shipments.length) {
                toast.warn('No shipment found with this code');
                return;
              }
              toast.success(res.msg);
              setPackageInputModal(false);
              navigate(
                `/databases/orders-archive/${res.shipments[0].order_id}/shipments`,
              );
            },
          );
        }}
      />
      <PopupWithOneInput
        open={goToOrderModal}
        onButtonOneClick={() => {
          setGoToOrderModal(false);
          return;
        }}
        headerTitle="Go to Order"
        inputOneLabel="Order ID"
        inputOnePlaceholder="Enter the order ID"
        inputOneValidator={(e: string) => !e}
        buttonTwoText="Go to Order"
        onButtonTwoClick={async (order_id: string) => {
          if (!order_id) return;
          const res =
            await ManagementOrdersRepository.getOrderDataByID(order_id);
          if (!res) {
            return;
          }
          navigate(`/databases/orders-archive/${order_id}/overview`);
          setGoToOrderModal(false);
        }}
      />
      <PopupWithOneInput
        open={goToListingModal}
        onButtonOneClick={() => {
          setGoToListingModal(false);
          return;
        }}
        headerTitle="Go to Listing"
        inputOneLabel="Listing ID"
        inputOnePlaceholder="Enter the listing ID"
        inputOneValidator={(e: string) => !e}
        buttonTwoText="Go to Listing"
        onButtonTwoClick={async (listing_id: string) => {
          if (!listing_id) return;
          navigate(`/products/listing-details/${listing_id}`);
          setGoToListingModal(false);
        }}
      />
      <PopupWithOneInput
        open={goToRequestModal}
        onButtonOneClick={() => {
          setGoToRequestModal(false);
          return;
        }}
        headerTitle="Go to Request"
        inputOneLabel="Request ID"
        inputOnePlaceholder="Enter the request ID"
        inputOneValidator={(e: string) => !e}
        buttonTwoText="Go to Request"
        onButtonTwoClick={async (request_id: string) => {
          if (!request_id) return;
          navigate(`/inquiries/requests/new-listings/${request_id}`);
          setGoToRequestModal(false);
        }}
      />
      <Sider
        collapsible
        collapsed={collapsed}
        theme="light"
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo">
          <h3>{collapsed ? 'Silal' : 'Silal Management'}</h3>
        </div>
        <Menu
          theme="light"
          defaultSelectedKeys={[menu[0].id]}
          selectedKeys={[selected]}
          mode="inline"
          items={items}
          forceSubMenuRender={true}
          style={{
            fontFamily: Fonts.primary,
            fontWeight: 500,
          }}
          onClick={(e) => go(e)}
          onSelect={(e) => go(e)}
        />
      </Sider>
      <div className="logout">
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            fullLogout(dispatch, navigate);
          }}
        >
          <div className="logout-link">
            <img src={Logouticon} alt="icon" />
            {!collapsed && <span>Log Out</span>}
          </div>
        </Link>
      </div>
      {(userManagementAvailableRoutes?.databases ||
        userManagementAvailableRoutes?.logistics) && (
        <FloatButton.Group
          trigger="hover"
          type="primary"
          style={{ right: 30 }}
          icon={<BsLightning />}
        >
          {userManagementAvailableRoutes?.databases && (
            <FloatButton
              tooltip="Go to Listing"
              onMouseOver={() => setHover4(true)}
              onMouseOut={() => setHover4(false)}
              onClick={() => {
                setGoToListingModal(true);
              }}
              icon={
                <LuShoppingBag
                  style={{
                    ...iconStyle,
                    color: hover4 ? Colors.greenMain : Colors.grey,
                  }}
                />
              }
            />
          )}{' '}
          {userManagementAvailableRoutes?.inquiries && (
            <FloatButton
              tooltip="Go to Request"
              onMouseOver={() => setHover5(true)}
              onMouseOut={() => setHover5(false)}
              onClick={() => {
                setGoToRequestModal(true);
              }}
              icon={
                <LuMessageSquare
                  style={{
                    ...iconStyle,
                    color: hover5 ? Colors.greenMain : Colors.grey,
                  }}
                />
              }
            />
          )}
          {userManagementAvailableRoutes?.databases && (
            <FloatButton
              tooltip="Go to Order"
              onMouseOver={() => setHover3(true)}
              onMouseOut={() => setHover3(false)}
              onClick={() => {
                setGoToOrderModal(true);
              }}
              icon={
                <TiShoppingCart
                  style={{
                    ...iconStyle,
                    color: hover3 ? Colors.greenMain : Colors.grey,
                  }}
                />
              }
            />
          )}
          {userManagementAvailableRoutes?.databases && (
            <FloatButton
              tooltip="Get Shipment By Code"
              onMouseOver={() => setHover2(true)}
              onMouseOut={() => setHover2(false)}
              onClick={() => {
                setPackageInputModal(true);
              }}
              icon={
                <LuPackageSearch
                  style={{
                    ...iconStyle,
                    color: hover2 ? Colors.greenMain : Colors.grey,
                  }}
                />
              }
            />
          )}
          {userManagementAvailableRoutes?.logistics && (
            <FloatButton
              tooltip="Generate Barcodes"
              onMouseOver={() => setHover1(true)}
              onMouseOut={() => setHover1(false)}
              onClick={() => {
                setGenerateBarcodesModal(true);
              }}
              icon={
                <FaBarcode
                  style={{
                    ...iconStyle,
                    color: hover1 ? Colors.greenMain : Colors.grey,
                  }}
                />
              }
            />
          )}
        </FloatButton.Group>
      )}
    </Wrapper>
  );
}

export default Sidenav;
