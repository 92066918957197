import { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { TableStyled, ThemeTabs } from 'silal_app_base_react/src/styles/style';
import {
  formatBankDetails,
  formatPrice,
} from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { notFoundIllustration_path } from 'silal_app_base_react/src/assets/index';
import FormControl from 'silal_app_base_react/src/components/form_control';
import { ArchivedOrdersWrapper } from 'silal_app_base_react/src/pages/orders_archive/components/archived_orders_components.styled';
import { ActionBtn } from './components/payments_page_components';
import TransactionsRepository from 'data/repositories/transactions_repository';
import {
  DriverPaymentReportTransaction,
  StorePaymentReportTransaction,
} from 'silal_app_base_react/src/data/types/transactions';
import Checkbox from 'silal_app_base_react/src/components/checkbox';
import { Link } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CheckboxGroup,
  Checkbox as CheckboxNext,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  RangeValue,
  Spacer,
} from '@nextui-org/react';
import { GrDocumentCsv, GrDocumentPdf, GrHtml5 } from 'react-icons/gr';
import StoresRepository from 'data/repositories/stores_repository';
import { Divider } from 'antd';
import {
  ORDER_STATUS,
  ORDER_STATUS_NAME_MAP,
} from 'silal_app_base_react/src/data/types/orders';
import { IoCheckmark, IoChevronDownOutline, IoClose } from 'react-icons/io5';
import { DateValue, parseDate } from '@internationalized/date';
import { CURRENT_CURRENCY } from 'silal_app_base_react/src/config/constants';
import { Helmet } from 'react-helmet-async';

export enum PaymentTab {
  STORES = 'stores',
  DRIVERS = 'drivers',
  ORDERS = 'orders',
}

const columnsWithName = {
  id: 'ID',
  order_id: 'Order ID',
  store_id: 'Store ID',
  customer_id: 'Customer ID',
  order_receipt_id: 'Order Receipt ID',
  payment_type_name: 'Payment Type',
  status_name: 'Status',
  placing_date: 'Placing Date',
  store_recieves: 'Store Recieves',
  customer_pays: 'Customer Pays',
  // payment_type: 'Payment Type',
  // status: 'Status',
  // last_mile_shift_id: 'Last Mile Shift ID',
  // delivery_fee: 'Delivery Fee',
  // vat: 'VAT',
  discounts_total: 'Discounts Total',
  items_total: 'Items Total',
  silal_fees_total: 'Silal Fees Total',
  order_receipt_date: 'Order Receipt Date',
  amount_paid_by_customer: 'Total Paid by Customer',
  amount_paid_by_customer_in_cash: 'Paid in Cash',
  amount_paid_by_customer_in_credit_card: 'Paid in Credit Card',
  amount_paid_by_customer_in_credit_balance: 'Paid in Credit Balance',
  amount_refunded: 'Total Refunded to Customer',
  amount_refunded_in_credit_balance: 'Amount Refunded in Credit Balance',
  amount_refunded_in_credit_card: 'Amount Refunded in Credit Card',
  debt_to_store_pre_deductions: 'Debt to Store Pre Deductions',
  amount_deducted_from_store_debt: 'Amount Deducted from Store Debt',
  pending_debt_to_store_balance: 'Pending Debt to Store Balance',
  net_debt_to_store: 'Net Debt to Store',
};

const columns = Object.keys(columnsWithName);

export const PaymentReportsArchive = () => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [checkAllForUpdate, setCheckAllForUpdate] = useState(false);
  const [driversPayments, setDriversPayments] = useState<
    DriverPaymentReportTransaction[]
  >([]);
  const [storesPayments, setStoresPayments] = useState<
    StorePaymentReportTransaction[]
  >([]);

  const [activeTab, setActiveTab] = useState(PaymentTab.STORES);
  const [search, setSearch] = useState('');

  const [cashOnly, setCashOnly] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set());
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const [dateRange, setDateRange] = useState<RangeValue<DateValue>>();

  const [selectedColumns, setSelectedColumns] = useState([
    'id',
    'store_id',
    'order_id',
    'customer_id',
    'order_receipt_id',
    'payment_type_name',
    'status_name',
    'placing_date',
    'store_recieves',
    'customer_pays',
  ]);

  useEffect(() => {
    async function fetchAllPayments() {
      if (activeTab === PaymentTab.STORES) {
        TransactionsRepository.getStoresPaymentReportsJSON([]).then((res) => {
          if (!res) return;
          setStoresPayments(res);
        });
        return;
      }
      TransactionsRepository.getDriversPaymentReportsJSON([]).then((res) => {
        if (!res) return;
        setDriversPayments(res);
      });
    }
    fetchAllPayments();
  }, [activeTab]);

  const driversColumns = [
    {
      name: (
        <div style={{ height: '20px', marginLeft: '7px' }}>
          <Checkbox
            title=""
            name="rating-checkbox"
            checked={checkAllForUpdate}
            onChange={(e: any) => {
              setCheckAllForUpdate(e.target.checked);
              if (e.target.checked) {
                if (activeTab === PaymentTab.DRIVERS) {
                  setCheckedIds(
                    driversPayments.map(
                      (item: DriverPaymentReportTransaction) => item.driver_id,
                    ),
                  );
                } else {
                  setCheckedIds(
                    storesPayments.map(
                      (item: StorePaymentReportTransaction) => item.store_id,
                    ),
                  );
                }
              } else {
                setCheckedIds([]);
              }
            }}
          />
          <Button
            color="success"
            variant="light"
            className="pb-3 ml-7"
            onPress={async () => {
              setCheckedIds(
                driversPayments
                  .filter(
                    (item: DriverPaymentReportTransaction) =>
                      parseFloat(item.revenue_balance_debt_to_driver) >= 10,
                  )
                  .map(
                    (item: DriverPaymentReportTransaction) => item.driver_id,
                  ),
              );
            }}
          >
            All +10{CURRENT_CURRENCY}
          </Button>
        </div>
      ),
      width: '8%',
      selector: (row: DriverPaymentReportTransaction) => {
        return (
          <div
            style={{
              height: '25px',
            }}
          >
            <Checkbox
              title={''}
              name="rating-checkbox"
              checked={checkedIds.includes(row.driver_id)}
              onChange={() => {
                if (checkedIds.includes(row.driver_id)) {
                  setCheckedIds(checkedIds.filter((i) => i !== row.driver_id));
                } else {
                  setCheckedIds([...checkedIds, row.driver_id]);
                }
              }}
            />
          </div>
        );
      },
    },
    {
      name: 'ID',
      width: '7%',
      selector: (row: DriverPaymentReportTransaction) => (
        <Link
          to={`/databases/drivers/${row.driver_id}/ledger`}
          style={{
            textDecoration: 'none',
            color: Colors.greenMain,
          }}
        >
          #{row.driver_id}
        </Link>
      ),
      sortable: true,
    },
    {
      name: 'Captains Name',
      selector: (row: DriverPaymentReportTransaction) => row.driver_name,
      sortable: true,
    },
    {
      name: 'Tax ID',
      selector: (row: DriverPaymentReportTransaction) => row.tax_id,
      sortable: true,
    },
    {
      name: 'Bank Details',
      selector: (row: DriverPaymentReportTransaction) =>
        formatBankDetails(
          row.bank_account_number,
          row.bank_branch_number,
          row.bank_number,
        ),
    },
    {
      name: 'DAS Rate',
      selector: (row: DriverPaymentReportTransaction) =>
        '%' +
        (row.das_percentage ? parseFloat(row.das_percentage) * 100 : 'N/A'),
    },
    {
      name: 'Cash Debt to Silal',
      selector: (row: DriverPaymentReportTransaction) =>
        row.cash_balance_debt_to_silal !== undefined
          ? formatPrice(row.cash_balance_debt_to_silal)
          : 'N/A',
      sortable: true,
    },
    {
      id: 'balance',
      name: 'Balance',
      selector: (row: DriverPaymentReportTransaction) =>
        row.revenue_balance_debt_to_driver !== undefined
          ? formatPrice(row.revenue_balance_debt_to_driver)
          : 'N/A',
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          a.revenue_balance_debt_to_driver - b.revenue_balance_debt_to_driver
        );
      },
    },
  ];

  const storesColumns = [
    {
      name: (
        <div style={{ height: '20px' }}>
          <Checkbox
            title=""
            name="rating-checkbox"
            checked={checkAllForUpdate}
            onChange={(e: any) => {
              setCheckAllForUpdate(e.target.checked);
              if (e.target.checked) {
                if (activeTab === PaymentTab.DRIVERS) {
                  setCheckedIds(
                    driversPayments.map(
                      (item: DriverPaymentReportTransaction) => item.driver_id,
                    ),
                  );
                } else {
                  setCheckedIds(
                    storesPayments.map(
                      (item: StorePaymentReportTransaction) => item.store_id,
                    ),
                  );
                }
              } else {
                setCheckedIds([]);
              }
            }}
          />
          <Button
            color="success"
            variant="light"
            className="pb-3 ml-7"
            onPress={async () => {
              setCheckedIds(
                storesPayments
                  .filter(
                    (item: StorePaymentReportTransaction) =>
                      parseFloat(item.balance) >= 10,
                  )
                  .map((item: StorePaymentReportTransaction) => item.store_id),
              );
            }}
          >
            All +10{CURRENT_CURRENCY}
          </Button>
        </div>
      ),
      width: '8%',
      selector: (row: StorePaymentReportTransaction) => {
        return (
          <div
            style={{
              height: '25px',
            }}
          >
            <Checkbox
              title={''}
              name="rating-checkbox"
              checked={checkedIds.includes(row.store_id)}
              onChange={() => {
                if (checkedIds.includes(row.store_id)) {
                  setCheckedIds(checkedIds.filter((i) => i !== row.store_id));
                } else {
                  setCheckedIds([...checkedIds, row.store_id]);
                }
              }}
            />
          </div>
        );
      },
    },
    {
      name: 'ID',
      width: '6%',
      selector: (row: StorePaymentReportTransaction) => (
        <Link
          to={`/databases/stores/${row.store_id}/ledger`}
          style={{
            textDecoration: 'none',
            color: Colors.greenMain,
          }}
        >
          #{row.store_id}
        </Link>
      ),
      sortable: true,
    },
    {
      name: 'Store Name',
      selector: (row: StorePaymentReportTransaction) => row.store_name,
      sortable: true,
    },
    {
      name: 'Tax ID',
      selector: (row: StorePaymentReportTransaction) => row.tax_id,
      sortable: true,
    },
    {
      name: 'Bank Details',
      selector: (row: StorePaymentReportTransaction) =>
        formatBankDetails(
          row.bank_account_number,
          row.bank_branch_number,
          row.bank_number,
        ),
    },
    {
      name: 'DAS Rate',
      selector: (row: StorePaymentReportTransaction) =>
        '%' +
        (row.das_percentage ? parseFloat(row.das_percentage) * 100 : 'N/A'),
    },
    {
      name: 'Orders Report',
      selector: (row: StorePaymentReportTransaction) => (
        <>
          <Button
            isIconOnly
            color="success"
            variant="light"
            onPress={async () => {
              await TransactionsRepository.getStoreOrdersReportCSV({
                store_id: row.store_id,
              });
            }}
          >
            <GrDocumentCsv size={20} />
          </Button>
          <Button
            isIconOnly
            color="success"
            variant="light"
            onPress={async () => {
              if (dateRange !== undefined) {
                var { day, month, year } = dateRange.start;
                const start_date = `${day}-${month}-${year}`;
                var { day, month, year } = dateRange.end;
                const end_date = `${day}-${month}-${year}`;
                await StoresRepository.getStoreOrdersReport(row.store_id, {
                  start_date,
                  end_date,
                  format: 'html',
                  downloadedFilename: `${row.store_name.replace(' ', '_')}_${`${start_date}_${end_date}`}_orders_report.html`,
                });
              } else {
                await StoresRepository.getStoreOrdersReport(row.store_id, {
                  format: 'html',
                  downloadedFilename: `${row.store_name.replace(' ', '_')}_orders_report.html`,
                });
              }
            }}
          >
            <GrHtml5 size={20} />
          </Button>
          <Button
            isIconOnly
            color="success"
            variant="light"
            onPress={async () => {
              if (dateRange !== undefined) {
                var { day, month, year } = dateRange.start;
                const start_date = `${day}-${month}-${year}`;
                var { day, month, year } = dateRange.end;
                const end_date = `${day}-${month}-${year}`;
                await StoresRepository.getStoreOrdersReport(row.store_id, {
                  start_date,
                  end_date,
                  format: 'pdf',
                  downloadedFilename: `${row.store_name.replace(' ', '_')}_${`${start_date}_${end_date}`}_orders_report.pdf`,
                });
              } else {
                await StoresRepository.getStoreOrdersReport(row.store_id, {
                  format: 'pdf',
                  downloadedFilename: `${row.store_name.replace(' ', '_')}_orders_report.pdf`,
                });
              }
            }}
          >
            <GrDocumentPdf size={20} />
          </Button>
        </>
      ),
    },
    {
      id: 'balance',
      name: (
        <span>
          Balance{' '}
          <Button
            color="success"
            variant="light"
            onPress={async () => {
              for (let i = 0; i < storesPayments.length; i++) {
                if (parseFloat(storesPayments[i].balance) >= 10) {
                  await StoresRepository.getStoreOrdersReport(
                    storesPayments[i].store_id,
                    {
                      format: 'pdf',
                      downloadedFilename: `${storesPayments[i].store_name.replaceAll(' ', '_')}_orders_report.pdf`,
                    },
                  );
                }
              }
            }}
          >
            Download +10ILS Reports
          </Button>
        </span>
      ),
      selector: (row: StorePaymentReportTransaction) =>
        row.balance !== undefined ? formatPrice(row.balance) : 'N/A',
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return a.balance - b.balance;
      },
    },
  ];

  const handleChange = (values: any) => {
    setSelectedColumns(values);
  };

  const handleStatusSelection = (key: any) => {
    const newKeys = new Set(selectedKeys);

    if (newKeys.has(key)) {
      newKeys.delete(key);
    } else if (newKeys.size < 3) {
      newKeys.add(key);
    }

    setSelectedKeys(newKeys);
    setSelectedStatuses(Array.from(newKeys));
  };

  return (
    <>
      <Helmet>
        <title>Payments | Silal Management</title>
      </Helmet>
      <h2
        className="poppins-font"
        style={{
          fontSize: '25px',
          fontWeight: 700,
          margin: '1rem 0rem 0rem 1rem',
        }}
      >
        Payment Reports Database
      </h2>
      <ArchivedOrdersWrapper>
        <div className="archive-content">
          <Tab.Container id="orders-tab-container" defaultActiveKey={activeTab}>
            <ThemeTabs
              className="tabs"
              style={{
                padding: '0px',
                paddingLeft: '15px',
                marginBottom: '0px',
              }}
            >
              <Nav variant="pills">
                <Nav.Item
                  onClick={() => {
                    setActiveTab(PaymentTab.STORES);
                    setCheckedIds([]);
                  }}
                >
                  <Nav.Link eventKey="stores">Stores</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => {
                    setActiveTab(PaymentTab.DRIVERS);
                    setCheckedIds([]);
                  }}
                >
                  <Nav.Link eventKey="drivers">Captains</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => {
                    setActiveTab(PaymentTab.ORDERS);
                    setCheckedIds([]);
                  }}
                >
                  <Nav.Link eventKey="orders">Orders</Nav.Link>
                </Nav.Item>
              </Nav>
            </ThemeTabs>
            {activeTab === PaymentTab.STORES ||
            activeTab === PaymentTab.DRIVERS ? (
              <>
                <FormControl
                  input={true}
                  type={'text'}
                  placeholder="Search by name, ID, or tax ID or bank details"
                  onChange={(e: any) => setSearch(e.target.value)} // TODO fix any
                />
                <div className="filters">
                  <ActionBtn
                    ids={checkedIds}
                    setDate={setDateRange}
                    dateRange={dateRange}
                    currentDatabase={activeTab}
                    cashDebtToSilal={
                      activeTab === PaymentTab.DRIVERS
                        ? driversPayments.reduce(
                            (acc, item) =>
                              acc + parseFloat(item.cash_balance_debt_to_silal),
                            0,
                          )
                        : undefined
                    }
                    totalBalance={
                      activeTab === PaymentTab.DRIVERS
                        ? driversPayments.reduce(
                            (acc, item) =>
                              acc +
                              parseFloat(item.revenue_balance_debt_to_driver),
                            0,
                          )
                        : storesPayments.reduce(
                            (acc, item) => acc + parseFloat(item.balance),
                            0,
                          )
                    }
                    refreshData={async () => {
                      if (activeTab === PaymentTab.STORES) {
                        TransactionsRepository.getStoresPaymentReportsJSON(
                          [],
                        ).then((res) => {
                          if (!res) return;
                          setStoresPayments(res);
                        });
                        return;
                      }
                      TransactionsRepository.getDriversPaymentReportsJSON(
                        [],
                      ).then((res) => {
                        if (!res) return;
                        setDriversPayments(res);
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              <Spacer y={5} />
            )}
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <TableStyled>
                  {activeTab === PaymentTab.DRIVERS &&
                  driversPayments &&
                  driversPayments.length !== 0 ? (
                    <DataTable
                      columns={driversColumns as any}
                      defaultSortFieldId={'balance'}
                      defaultSortAsc={false}
                      data={driversPayments.filter(
                        (item: DriverPaymentReportTransaction) =>
                          search === '' ||
                          item.driver_name.includes(search) ||
                          item.driver_id.toString().includes(search) ||
                          item.tax_id.includes(search) ||
                          item.bank_account_number
                            .toString()
                            .includes(search) ||
                          item.bank_branch_number.toString().includes(search) ||
                          item.bank_number.toString().includes(search),
                      )}
                      pagination
                      paginationPerPage={100}
                    />
                  ) : activeTab === PaymentTab.STORES &&
                    storesPayments &&
                    storesPayments.length !== 0 ? (
                    <DataTable
                      columns={storesColumns as any}
                      defaultSortFieldId={'balance'}
                      defaultSortAsc={false}
                      data={storesPayments.filter(
                        (item: StorePaymentReportTransaction) =>
                          search === '' ||
                          item.store_name.includes(search) ||
                          item.store_id.toString().includes(search) ||
                          item.tax_id.includes(search) ||
                          item.bank_account_number
                            .toString()
                            .includes(search) ||
                          item.bank_branch_number.toString().includes(search) ||
                          item.bank_number.toString().includes(search),
                      )}
                      pagination
                      paginationPerPage={100}
                    />
                  ) : activeTab === PaymentTab.ORDERS ? (
                    <Card>
                      <CardHeader className="text-2xl font-bold">
                        Orders Report
                      </CardHeader>
                      <CardBody>
                        <CheckboxGroup
                          label="Select columns to include in the CSV"
                          value={selectedColumns}
                          onChange={handleChange}
                          orientation="horizontal"
                        >
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {columns.map((column) => (
                              <div
                                key={column}
                                style={{
                                  flex: '1 0 20%', // 100% / 6 columns per row
                                  boxSizing: 'border-box',
                                  padding: '5px',
                                }}
                              >
                                <CheckboxNext value={column}>
                                  {
                                    columnsWithName[
                                      column as keyof typeof columnsWithName
                                    ]
                                  }
                                </CheckboxNext>
                              </div>
                            ))}
                          </div>
                          <Divider />
                          <h4>Settings</h4>
                          <div className="flex gap-4 w-full">
                            <div>
                              <CheckboxNext
                                isSelected={cashOnly}
                                onValueChange={(value) => setCashOnly(value)}
                              >
                                Cash Only Transactions
                              </CheckboxNext>
                            </div>
                            <Dropdown
                              closeOnSelect={false}
                              shouldBlockScroll={true}
                              showArrow
                            >
                              <DropdownTrigger>
                                <Button
                                  variant="bordered"
                                  className="flex justify-between w-full"
                                  style={{
                                    height: 'fit-content',
                                    minHeight: '3rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                  }}
                                  endContent={
                                    <IoChevronDownOutline className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                                  }
                                >
                                  {selectedStatuses.length > 0
                                    ? selectedStatuses
                                        .map(
                                          (status: any) =>
                                            ORDER_STATUS_NAME_MAP[
                                              status as keyof typeof ORDER_STATUS_NAME_MAP
                                            ],
                                        )
                                        .join(', ')
                                    : 'Select up to 3 statuses, if none selected, all statuses will be included'}
                                </Button>
                              </DropdownTrigger>
                              <DropdownMenu
                                aria-label="Order Status Selection"
                                disallowEmptySelection={false}
                                selectionMode="multiple"
                                closeOnSelect={false}
                              >
                                {Object.keys(ORDER_STATUS)
                                  .filter((key) => isNaN(Number(key)))
                                  .map((key, index) => {
                                    return (
                                      <DropdownItem
                                        key={index}
                                        selectedIcon={
                                          selectedKeys.has(
                                            ORDER_STATUS[
                                              key as keyof typeof ORDER_STATUS
                                            ].toString(),
                                          ) ? (
                                            <IoCheckmark />
                                          ) : null
                                        }
                                        onPress={() =>
                                          handleStatusSelection(
                                            ORDER_STATUS[
                                              key as keyof typeof ORDER_STATUS
                                            ],
                                          )
                                        }
                                      >
                                        {
                                          ORDER_STATUS_NAME_MAP[
                                            ORDER_STATUS[
                                              key as keyof typeof ORDER_STATUS
                                            ]
                                          ]
                                        }
                                      </DropdownItem>
                                    );
                                  })}
                              </DropdownMenu>
                            </Dropdown>
                            <Button
                              onPress={() => {
                                setSelectedStatuses([]);
                                setSelectedKeys(new Set());
                              }}
                              isIconOnly
                              variant="flat"
                              color="danger"
                            >
                              <IoClose />
                            </Button>
                          </div>
                        </CheckboxGroup>
                      </CardBody>
                      <CardFooter className="justify-end">
                        <Button
                          color="success"
                          onPress={async () => {
                            await TransactionsRepository.getStoreOrdersReportCSV(
                              {
                                columns: selectedColumns,
                                cash_only: cashOnly,
                                status1: selectedStatuses[0],
                                status2: selectedStatuses[1],
                                status3: selectedStatuses[2],
                              },
                            );
                          }}
                        >
                          Download CSV
                        </Button>
                      </CardFooter>
                    </Card>
                  ) : (
                    <div className="no-orders">
                      <img src={notFoundIllustration_path} alt="no-orders" />
                      <h3>No reports found</h3>
                    </div>
                  )}
                </TableStyled>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </ArchivedOrdersWrapper>
    </>
  );
};
