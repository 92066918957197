import { Icon } from '@iconify/react';

import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
// import ApplicationChecklist from '../components/application_checklist';
import { missingInfoStyle } from '../components/request_base_view.styled';
import { WrapperViewDetail } from '../components/request_base_view.styled';
import { ImageType } from 'silal_app_base_react/src/data/types/files';
import { BusinessAddListingRequest } from 'data/types/requests';
import { ListingItem } from 'silal_app_base_react/src/data/types/listings';
import { AttributeAssociation } from 'silal_app_base_react/src/data/types/listings';
import {
  formatAttributeName,
  formatAttributeValues,
  formatDecimals,
  formatPrice,
  sanitizeHTML,
} from 'silal_app_base_react/src/utils/functions/formatting_functions';
import StoresRepository from 'data/repositories/stores_repository';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { Link } from 'react-router-dom';
import { getUniqueValues } from 'silal_app_base_react/src/pages/listing_profile/screens/listing_info_screen';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import { DoubleInputPopupModal } from 'components/popup/popup_modal';
import CategoriesRepository from 'data/repositories/categories_repository';
import { toast } from 'react-toastify';
import { SanitizedHTMLStyle } from 'silal_app_base_react/src/styles/style';
import { useDispatch } from 'react-redux';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { EN_AR_TRANSLATION_SPLIT_CHAR } from 'silal_app_base_react/src/pages/create_listings/constants';
import { CategoryParentsBreadcrumbs } from 'silal_app_base_react/src/components/categories_breadcrubmbs';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { AbstractCategory } from 'silal_app_base_react/src/data/types/categories';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import {
  SelectAttribute,
  NumericAttribute,
  BooleanAttribute,
} from 'data/types/attributes';
import { FaPencil } from 'react-icons/fa6';
import { UpdateNameOrDescriptionModal } from 'silal_app_base_react/src/pages/listing_profile/modals/update_name_desc_modal';
import DataEditingRepository from 'data/repositories/data_editing_repository';
import { SimilarListingsCard } from '../components/similar_items_card';

import { Helmet } from 'react-helmet-async';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Select,
  SelectSection,
  SelectItem,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Switch,
  Tooltip,
} from '@nextui-org/react';
import { Col, Row } from 'react-bootstrap';
import { Autocomplete, AutocompleteItem } from '@nextui-org/react';
import { PerplexityResponse } from 'silal_app_base_react/src/data/types/utilities';
import UtilitiesRepository from 'silal_app_base_react/src/data/repositories/utilities_repository';

type ListingsRequestViewProps = {
  cardData: BusinessAddListingRequest;
  isChecked: boolean;
  setIsChecked: () => void;
};

export default function NewListingsRequestView({
  cardData,
  // isChecked,
  // setIsChecked,
}: ListingsRequestViewProps) {
  const dispatch = useDispatch();

  const [index, setIndex] = useState(-1);
  const image = cardData?.listing?.images?.[index];
  const currentImage = cardData?.listing?.images?.[index];
  const nextIndex = (index + 1) % cardData?.listing?.images?.length;
  const nextImage = cardData?.listing?.images?.[nextIndex] || currentImage;
  const prevIndex =
    (index + cardData?.listing?.images?.length - 1) %
    cardData?.listing?.images?.length;
  const prevImage = cardData?.listing?.images?.[prevIndex] || currentImage;
  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => {
    setIndex(-1);
  };
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const [lists, setList] = useState<number[]>([]);
  const [fieldsNullFlags, setfieldsNullFlags] = useState({
    has_name: false,
    has_second_name: false,
    has_category_name: false,
    has_rich_text_description: false,
    has_images: false,
    has_combinations: false,
    has_attributes: false,
    has_whats_included: false,
    has_weight: false,
    has_width: false,
    has_height: false,
    has_depth: false,
    has_item_qualities: false,
    has_time_to_ship: false,
  });
  const [same_attributes, setSameAttributes] = useState<string[]>([]);
  const [category, setCategory] = useState<AbstractCategory>();
  const [storeInfo, setStoreInfo] = useState<StoreDetails>();

  const openLightBox = (index: number) => {
    handleClick(index);
    setIndex(index);
  };

  useEffect(() => {
    const fetchStoreInfo = async () => {
      await StoresRepository.getStoreDetails(cardData.store_id.toString()).then(
        (res) => {
          if (!res) return;
          setStoreInfo(res);
          setTimeout(() => {
            setList([0, 1, 2, 3, 4, 5, 6]);
          }, 100);
        },
      );
    };
    fetchStoreInfo();
  }, [cardData?.id]);

  useEffect(() => {
    setSameAttributes(
      cardData?.listing?.items[0].attributes?.map((i) => i.attr_name_en),
    );
    setfieldsNullFlags({
      has_name: cardData?.listing?.name_en ? true : false,
      has_second_name: cardData?.listing?.name_ar ? true : false,
      has_category_name: true,
      has_rich_text_description: cardData?.listing?.rich_text_description_en
        ? true
        : false,
      has_images: cardData?.listing?.images ? true : false,
      has_combinations: true,
      has_attributes: cardData?.listing?.attributes ? true : false,
      has_whats_included: cardData?.listing?.whats_included ? true : false,
      has_weight: cardData?.listing?.weight ? true : false,
      has_width: cardData?.listing?.width ? true : false,
      has_height: cardData?.listing?.height ? true : false,
      has_depth: cardData?.listing?.length ? true : false,
      has_item_qualities: true,
      has_time_to_ship: cardData?.listing?.time_to_ship ? true : false,
    });
  }, [cardData]);

  useEffect(() => {
    dispatch(
      actionGetCategoriesMinimalList({
        success: (minimalCategoriesList: AbstractCategory[]) =>
          setCategory(
            minimalCategoriesList.find(
              (c) => c.id === cardData?.listing?.category_id,
            ),
          ),
      }),
    );
  }, [cardData, cardData?.listing?.category_id]);

  const toggleCollapse = (number: number) => {
    if (lists.includes(number)) {
      setList(lists.filter((i) => i !== number));
    } else {
      setList([...lists, number]);
    }
  };

  function getAppropriateItemQualities() {
    const qualities: string[] = [];
    if (cardData?.listing?.fragile) qualities.push('Breakable');
    if (cardData?.listing?.keep_dry) qualities.push('Contain Liquid');
    if (cardData?.listing?.flammable) qualities.push('Flammable');

    return qualities.length === 0
      ? 'Nothing was specified'
      : qualities.join(', ');
  }

  return (
    <div>
      {image && (
        <Lightbox
          mainSrc={image.public_s3_url}
          imageTitle={`# ${currentImage.id} ` + (image.alt || 'No Alt Text')}
          mainSrcThumbnail={image.public_s3_url}
          nextSrc={
            nextImage.public_s3_url || nextImage.get_object_presigned_url
          }
          nextSrcThumbnail={nextImage.alt}
          prevSrc={
            prevImage.public_s3_url || prevImage.get_object_presigned_url
          }
          prevSrcThumbnail={prevImage.alt}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}

      <WrapperViewDetail>
        <div className="container">
          <motion.div
            className="left-side"
            style={{
              flex: 9,
            }}
          >
            <div className="grid-row-three">
              <div className="grid-column">
                <button
                  className="lato-font"
                  style={{ padding: '10px' }}
                  onClick={() => toggleCollapse(0)}
                >
                  STORE INFORMATION
                  <span>
                    {lists.includes(0) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(0) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row-2 lato-font">
                        <div className="row">
                          <p className="left-column">Store ID </p>
                          <Link
                            to={`/databases/stores/${cardData?.store_id}/profile`}
                            style={{
                              color: Colors.greenMain,
                              textDecoration: 'underline',
                            }}
                          >
                            #{storeInfo?.id}
                          </Link>
                        </div>
                        <div className="row">
                          <p className="left-column">Store name </p>
                          <p className="right-column">
                            {storeInfo?.name_en + ' | ' + storeInfo?.name_ar}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">Tax-DAS-Bank</p>
                          <div
                            className="right-column"
                            style={{
                              display: 'flex',
                            }}
                          >
                            <a
                              href={
                                storeInfo?.business_tax_certificate
                                  ?.get_object_presigned_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon icon="akar-icons:eye" />
                            </a>
                            <div style={{ width: '10px' }} />
                            <a
                              href={
                                storeInfo?.business_das_certificate
                                  ?.get_object_presigned_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon icon="akar-icons:eye" />
                            </a>
                            <div style={{ width: '10px' }} />
                            <a
                              href={
                                storeInfo?.business_bank_statement
                                  ?.get_object_presigned_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon icon="akar-icons:eye" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <div className="grid-column">
                <button
                  className="lato-font"
                  style={{ padding: '10px' }}
                  onClick={() => toggleCollapse(1)}
                >
                  ITEM INFORMATION
                  <span>
                    {lists.includes(1) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(1) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row-2 lato-font">
                        <div className="row">
                          <p className="left-column">Listing ID </p>
                          <p className="right-column">
                            <Link
                              to={`/products/listing-details/${cardData?.listing?.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: Colors.greenMain,
                                textDecoration: 'underline',
                                fontFamily: Fonts.primary,
                                fontWeight: 600,
                                fontSize: '13px',
                              }}
                            >
                              #{cardData?.listing?.id}
                            </Link>{' '}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">Listing name</p>
                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={cardData?.listing as any}
                            typeOfText="name"
                            onUpdated={async () => {
                              toast.success(
                                'Name updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div className="flex">
                                {' '}
                                <p
                                  className="right-column"
                                  style={
                                    fieldsNullFlags.has_name
                                      ? {}
                                      : missingInfoStyle
                                  }
                                >
                                  {fieldsNullFlags.has_name
                                    ? cardData?.listing?.name_en
                                    : 'Not Specified'}
                                </p>
                                <FaPencil
                                  className="ml-2 mt-1 cursor-pointer"
                                  color="gray"
                                />
                              </div>
                            }
                          />
                        </div>
                        <div className="row">
                          <p className="left-column">
                            Listing name (2nd language)
                          </p>
                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={cardData?.listing as any}
                            typeOfText="name"
                            onUpdated={async () => {
                              toast.success(
                                'Name updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div className="flex">
                                {' '}
                                <p
                                  className="right-column"
                                  style={
                                    fieldsNullFlags.has_second_name
                                      ? {}
                                      : missingInfoStyle
                                  }
                                >
                                  {fieldsNullFlags.has_second_name
                                    ? cardData?.listing?.name_ar
                                    : 'Not Specified'}
                                </p>
                                <FaPencil
                                  className="ml-2 mt-1 cursor-pointer"
                                  color="gray"
                                />
                              </div>
                            }
                          />
                        </div>

                        <div className="row">
                          <p className="left-column">Category</p>
                          <div
                            className="right-column"
                            style={
                              fieldsNullFlags.has_category_name
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {category && (
                              <CategoryParentsBreadcrumbs category={category} />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <p className="left-column">Condition</p>
                          <p className="right-column">
                            {cardData?.listing?.is_used === true
                              ? 'Used'
                              : 'New '}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">
                            About the item (ENGLISH)
                          </p>
                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={cardData?.listing as any}
                            typeOfText="description"
                            onUpdated={async () => {
                              toast.success(
                                'Description updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <SanitizedHTMLStyle
                                  style={{
                                    maxHeight: '500px',
                                    overflow: 'auto',
                                  }}
                                  dangerouslySetInnerHTML={sanitizeHTML(
                                    fieldsNullFlags.has_rich_text_description
                                      ? cardData?.listing
                                          ?.rich_text_description_en
                                      : 'Not Specified',
                                  )}
                                />
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>
                        <div className="row">
                          <p className="left-column">About the item (عربي)</p>

                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={cardData?.listing as any}
                            typeOfText="description"
                            onUpdated={async () => {
                              toast.success(
                                'Description updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <SanitizedHTMLStyle
                                  style={{
                                    direction: 'rtl',
                                    maxHeight: '500px',
                                    overflow: 'auto',
                                  }}
                                  dangerouslySetInnerHTML={sanitizeHTML(
                                    fieldsNullFlags.has_rich_text_description
                                      ? cardData?.listing
                                          ?.rich_text_description_ar
                                      : 'غير محدد',
                                  )}
                                />
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>
                        <div // <Icon
                          className="images_grid"
                          style={
                            fieldsNullFlags.has_images ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_images
                            ? cardData?.listing?.images?.map(
                                (image: ImageType, index) => {
                                  return (
                                    <ShowImage
                                      style={{
                                        border:
                                          image.id ===
                                          cardData?.listing?.cover_image_id
                                            ? `2px solid ${Colors.greenMain}`
                                            : 'none',
                                      }}
                                      key={index}
                                      image={image}
                                      onClick={() => {
                                        openLightBox(index);
                                      }}
                                    />
                                  );
                                },
                              )
                            : 'Not Specified'}
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="grid-column">
                <button
                  className="lato-font"
                  onClick={() => toggleCollapse(4)}
                  style={{
                    padding: '10px',
                    marginTop: '5px',
                  }}
                >
                  ATTRIBUTES{' '}
                  {category && (
                    <CategoryParentsBreadcrumbs category={category} />
                  )}
                  <span>
                    {lists.includes(4) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(4) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <RequestAttributeSection
                        cardData={cardData}
                        fieldsNullFlags={fieldsNullFlags}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="grid-column">
                <button
                  className="lato-font"
                  onClick={() => toggleCollapse(5)}
                  style={{
                    padding: '10px',
                  }}
                >
                  COMBINATION TABLE
                  <span>
                    {lists.includes(5) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(5) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row">
                        <span>
                          All <span className="green">VALID</span> Combinations
                          of attributes that have{' '}
                          <span className="green">multiple</span> values
                        </span>

                        <div className="row-3">
                          <motion.table className="lato-font">
                            <thead>
                              <tr>
                                <th>ID</th>
                                {same_attributes?.map((attr, index) => {
                                  return <th key={index}>{attr}</th>;
                                })}
                                <th>Price</th>
                                <th>Image</th>
                                <th>In Stock</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cardData?.listing?.items?.map(
                                (item: ListingItem, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="form-check">
                                          #{item.id}
                                        </div>
                                      </td>
                                      {same_attributes?.map((attr, index) => {
                                        const attrObj = item.attributes?.find(
                                          (i) => i.attr_name_en === attr,
                                        );
                                        return (
                                          <td key={index}>
                                            {formatAttributeValues(
                                              attrObj?.attr_value_en?.toString(),
                                              attrObj?.attr_value_ar?.toString(),
                                              attrObj?.attribute_type || 0,
                                            )}
                                          </td>
                                        );
                                      })}

                                      <td>{formatPrice(item.price)}</td>
                                      <td>
                                        <div className="images">
                                          {cardData?.listing?.images?.findIndex(
                                            (i) => i.id === item.image?.id,
                                          ) !== undefined ? (
                                            <ShowImage
                                              image={item.image}
                                              onClick={() => {
                                                openLightBox(
                                                  cardData?.listing?.images?.findIndex(
                                                    (i) =>
                                                      i.id === item.image?.id,
                                                  ),
                                                );
                                              }}
                                            />
                                          ) : (
                                            'No Variant Image'
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-check">
                                          {item.in_stock ? 'True' : 'False'}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            </tbody>
                          </motion.table>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="grid-column">
                <button
                  className="lato-font"
                  onClick={() => toggleCollapse(6)}
                  style={{
                    padding: '10px',
                  }}
                >
                  SHIPPING
                  <span>
                    {lists.includes(6) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(6) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row-2 lato-font">
                        <div className="row">
                          <p className="left-column">What’s in Box</p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_whats_included
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {fieldsNullFlags.has_whats_included
                              ? cardData?.listing?.whats_included
                              : 'Not Specified'}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">
                            Package Dimensions (w/d/h){' '}
                          </p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_depth &&
                              fieldsNullFlags.has_height &&
                              fieldsNullFlags.has_width
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {fieldsNullFlags.has_width
                              ? formatDecimals(cardData?.listing?.width) + 'cm'
                              : 'NA'}
                            ,{' '}
                            {fieldsNullFlags.has_depth
                              ? formatDecimals(cardData?.listing?.length) + 'cm'
                              : 'NA'}
                            ,{' '}
                            {fieldsNullFlags.has_height
                              ? formatDecimals(cardData?.listing?.height) + 'cm'
                              : 'NA'}
                            ,
                          </p>
                        </div>{' '}
                        <div className="row">
                          <p className="left-column">Package Weight</p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_weight ? {} : missingInfoStyle
                            }
                          >
                            {fieldsNullFlags.has_weight
                              ? parseFloat(cardData?.listing?.weight) > 1000
                                ? (
                                    parseFloat(cardData?.listing?.weight) / 1000
                                  ).toFixed(3) + 'kg'
                                : formatDecimals(cardData?.listing?.weight) +
                                  'g'
                              : 'NA'}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">Item qualities</p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_item_qualities
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {fieldsNullFlags.has_item_qualities
                              ? getAppropriateItemQualities()
                              : 'Not Specified'}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">Delivery time</p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_time_to_ship
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {fieldsNullFlags.has_time_to_ship
                              ? cardData?.listing?.time_to_ship
                              : 'Not Specified'}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </motion.div>
          <div
            style={{
              flex: 4,
            }}
          >
            <SimilarListingsCard
              listing_name_en={cardData.listing?.name_en}
              listing_name_ar={cardData.listing?.name_ar}
              store_id={cardData.store_id.toString()}
              listing_id={cardData.listing?.id.toString()}
            />
          </div>
        </div>
      </WrapperViewDetail>
    </div>
  );
}

export const RequestAttributeSection = ({
  cardData,
  fieldsNullFlags,
  factCheckAttributes, // Pass true if you want to show the AI check column
  attributeFactCheckMap, // Pass the map of attribute id to PerplexityResponse, calculate outside the table to only calculate once (i.e used in Listing Reivew Model tabs)
}: {
  cardData: BusinessAddListingRequest;
  fieldsNullFlags?: Record<string, boolean>;
  factCheckAttributes?: boolean;
  attributeFactCheckMap?: Record<string, PerplexityResponse>;
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [_, setWantsToChangeToAnotherValue] = useState(false);
  const [showAppendAttributeValuesModal, setShowAppendAttributeValuesModal] =
    useState(false);
  const [attributesFullObjectsMap, setAttributesFullObjectsMap] = useState<
    Record<string, SelectAttribute | NumericAttribute | BooleanAttribute>
  >({});

  const [attributeToUpdate, setAttributeToUpdate] = useState<
    SelectAttribute | NumericAttribute | BooleanAttribute
  >();
  const [arAttributeValue, setArAttributeValue] = useState<string>('');
  const [enAttributeValue, setEnAttributeValue] = useState<string>('');
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [index, setIndex] = useState(-1);
  const image = cardData?.listing?.images?.[index];
  const currentImage = cardData?.listing?.images?.[index];
  const nextIndex = (index + 1) % cardData?.listing?.images?.length;
  const nextImage = cardData?.listing?.images?.[nextIndex] || currentImage;
  const prevIndex =
    (index + cardData?.listing?.images?.length - 1) %
    cardData?.listing?.images?.length;
  const prevImage = cardData?.listing?.images?.[prevIndex] || currentImage;
  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => {
    setIndex(-1);
  };
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    const fetchAttributesInfo = async () => {
      await SharedRepository.getOfficialValuesOfAttributes(
        Object.keys(cardData?.listing?.official_attributes || {}).map((key) =>
          parseInt(key),
        ),
      ).then((res: SelectAttribute[]) => {
        if (!res) return;
        const map: Record<string, SelectAttribute> = {};
        res.forEach((attr) => {
          map[attr.id] = attr;
        });
        setAttributesFullObjectsMap(map);
      });
    };
    fetchAttributesInfo();
  }, [cardData?.id]);

  async function submitNewValues() {
    console.log('Attribute to update:', attributeToUpdate);

    if (!attributeToUpdate) {
      toast.warn('Please select an attribute');
      return;
    }

    // Check if attribute is of type SELECT and if so, check official values
    if (isSelectAttribute(attributeToUpdate)) {
      if (!enAttributeValue || !arAttributeValue) {
        toast.warn('Please fill in both fields');
        return;
      }

      if (
        attributeToUpdate.official_values_en?.includes(enAttributeValue) ||
        attributeToUpdate.official_values_ar?.includes(arAttributeValue)
      ) {
        setConfirmModal(true);
        toast.warn(
          'This value already exists in the attribute values, click confirm to change to an existing one.',
        );
        return;
      }

      await CategoriesRepository.appendSelectAttributeValues(
        attributeToUpdate.id,
        [enAttributeValue],
        [arAttributeValue],
      )
        .then(async (res) => {
          if (!res) return;

          await CategoriesRepository.bulkEditAttributes(
            [
              {
                listing_id: cardData.listing!.id,
                new_value_en: enAttributeValue,
              },
            ],
            attributeToUpdate.id,
            attributeToUpdate.type,
          )
            .then((res) => {
              if (!res) return;
              setArAttributeValue('');
              setEnAttributeValue('');
              setAttributeToUpdate(undefined);
              setShowAppendAttributeValuesModal(false);
              toast.success('Attribute updated successfully!');
            })
            .catch(() => {
              toast.error('Failed to update attribute.');
            });
        })
        .catch(() => {
          toast.error('Failed to append attribute values.');
        });

      return;
    }

    // Handle other attribute types (BOOLEAN, NUMERIC) as before...
    if (attributeToUpdate.type === AttributeType.BOOLEAN) {
      if (enAttributeValue !== 'true' && enAttributeValue !== 'false') {
        toast.warn('Please enter a valid boolean value (true or false)');
        return;
      }
    } else if (attributeToUpdate.type === AttributeType.NUMERIC) {
      if (isNaN(Number(enAttributeValue))) {
        toast.warn('Please enter a valid numeric value');
        return;
      }
    }

    // Process for other types like BOOLEAN and NUMERIC...
    let newValue: string | number | boolean = enAttributeValue;
    if (attributeToUpdate.type === AttributeType.BOOLEAN) {
      newValue = enAttributeValue === 'true';
    } else if (attributeToUpdate.type === AttributeType.NUMERIC) {
      newValue = Number(enAttributeValue);
    }

    const payload = [
      {
        listing_id: cardData.listing!.id,
        new_value_en: newValue,
      },
    ];

    await CategoriesRepository.bulkEditAttributes(
      payload,
      attributeToUpdate.id,
      attributeToUpdate.type,
    )
      .then((res: any) => {
        if (res) {
          setArAttributeValue('');
          setEnAttributeValue('');
          setAttributeToUpdate(undefined);
          setShowAppendAttributeValuesModal(false);
          toast.success('Attribute updated successfully!');
        }
      })
      .catch(() => {
        toast.error('Failed to update attribute.');
      });
  }

  function isSelectAttribute(attribute: any): attribute is SelectAttribute {
    return (
      attribute &&
      'official_values_en' in attribute &&
      'official_values_ar' in attribute
    );
  }

  const gridClass = factCheckAttributes
    ? '1fr 1fr 1fr 1fr 1fr'
    : '1fr 2fr 2fr 1fr';

  return (
    <>
      {' '}
      <div className="item-info lato-font center-text">
        <div
          className="row"
          style={{
            gridTemplateColumns: gridClass,
          }}
        >
          <p className="right-column header">Attribute Name</p>
          <p className="right-column header">Attribute value(s)</p>
          {factCheckAttributes && (
            <p className="right-column header">AI Check</p>
          )}
          <p className="third-column header">Images</p>
          <p className="third-column header">Added By</p>
        </div>
        <div
          className="row"
          style={{
            gridTemplateColumns: gridClass,
          }}
        >
          {Object.keys(cardData?.listing?.official_attributes || {}).map(
            (keyName, index) => {
              const obj = cardData?.listing?.official_attributes[
                keyName as any
              ] as AttributeAssociation;
              const relevantAttribute = attributesFullObjectsMap[obj.attr_id];
              const imageSet = new Set();
              return (
                <Fragment key={index}>
                  <p className="left-column tab">
                    {formatAttributeName(obj.attr_name_en, obj.attr_name_ar)}
                    {obj?.attribute_full_object?.unit && (
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {' (' + obj?.attribute_full_object?.unit + ')'}
                      </span>
                    )}
                  </p>
                  <div className="right-column tab">
                    {getUniqueValues(obj).map((item, i) => {
                      if (
                        isSelectAttribute(relevantAttribute) &&
                        obj.attribute_full_object.type === AttributeType.SELECT
                      ) {
                        const itemEn = item
                          .split(EN_AR_TRANSLATION_SPLIT_CHAR)[0]
                          ?.trim();
                        const itemAr = item
                          .split(EN_AR_TRANSLATION_SPLIT_CHAR)[1]
                          ?.trim();
                        const isOfficialEn =
                          relevantAttribute.official_values_en.includes(itemEn);
                        const isOfficialAr =
                          relevantAttribute.official_values_ar.includes(itemAr);

                        if (!isOfficialEn || !isOfficialAr) {
                          return (
                            <span
                              title="This value is not official, click to add it to the official values"
                              key={i}
                              style={{
                                color: Colors.blue,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setAttributeToUpdate(
                                  attributesFullObjectsMap[obj.attr_id],
                                );
                                setEnAttributeValue(itemEn);
                                setArAttributeValue(itemAr);
                                setShowAppendAttributeValuesModal(true);
                                onOpen();
                              }}
                            >
                              {item}{' '}
                            </span>
                          );
                        }

                        return (
                          <div
                            key={i}
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAttributeToUpdate(
                                attributesFullObjectsMap[obj.attr_id],
                              );
                              setEnAttributeValue(itemEn);
                              setArAttributeValue(itemAr);
                              setShowAppendAttributeValuesModal(true);
                              onOpen();
                            }}
                          >
                            {item}{' '}
                          </div>
                        );
                      }
                      if (
                        obj.attribute_full_object.type === AttributeType.BOOLEAN
                      ) {
                        return (
                          <div
                            title="This value is not official, click to add it to the official values"
                            key={i}
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAttributeToUpdate(obj.attribute_full_object);
                              setEnAttributeValue(item);
                              setShowAppendAttributeValuesModal(true);
                              onOpen();
                            }}
                          >
                            {item}{' '}
                          </div>
                        );
                      }
                      if (
                        obj.attribute_full_object.type === AttributeType.NUMERIC
                      ) {
                        return (
                          <div
                            title="This value is not official, click to add it to the official values"
                            key={i}
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAttributeToUpdate(obj.attribute_full_object);
                              setEnAttributeValue(item);
                              setShowAppendAttributeValuesModal(true);
                              onOpen();
                            }}
                          >
                            {item}{' '}
                          </div>
                        );
                      }
                    })}
                  </div>
                  {factCheckAttributes && (
                    <div
                      className="flex flex-row justify-center gap-4 align-center"
                      style={{
                        color: UtilitiesRepository.getSimilarityColor(
                          attributeFactCheckMap?.[obj.attr_id]?.answer ?? '',
                          cardData?.listing?.official_attributes[
                            obj.attr_id
                          ]?.attr_value_en.toString(),
                        ),
                        fontWeight: 'bold',
                      }}
                    >
                      {attributeFactCheckMap &&
                        attributeFactCheckMap[obj.attr_id]?.answer}
                      <Tooltip
                        color="default"
                        placement="top"
                        content={
                          <div className="px-1 py-2">
                            <div className="text-small font-bold">Prompt:</div>
                            <div className="text-tiny">
                              {attributeFactCheckMap &&
                                attributeFactCheckMap[obj.attr_id]?.prompt}
                            </div>
                            <br />
                            <div className="text-small font-bold">
                              Citations:
                            </div>
                            <div className="text-tiny">
                              {attributeFactCheckMap &&
                                attributeFactCheckMap[
                                  obj.attr_id
                                ]?.citations?.map((citation, i) => (
                                  <div key={i} className="p-2">
                                    <a href={citation} target="_blank">
                                      {citation}
                                    </a>
                                  </div>
                                ))}
                            </div>
                          </div>
                        }
                      >
                        <Icon icon="akar-icons:info" />
                      </Tooltip>
                    </div>
                  )}
                  <div className="third-column">
                    <div className="images">
                      {obj.values_images_items?.map((item, j) => {
                        if (imageSet.has(item.value_en)) {
                          return;
                        }
                        imageSet.add(item.value_en);
                        return (
                          <Fragment key={j}>
                            {!item.image || item.image === null ? (
                              <p>N/A-</p>
                            ) : (
                              <ShowImage
                                image={item.image}
                                onClick={() => {
                                  handleClick(
                                    cardData?.listing?.images?.findIndex(
                                      (i) => i.id === item.image?.id,
                                    ),
                                  );
                                }}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <p
                    className="forth-column tab"
                    style={{
                      color: Colors.greenMain,
                    }}
                  >
                    SILAL
                  </p>
                </Fragment>
              );
            },
          )}
          {Object.keys(cardData?.listing?.custom_attributes || {}).map(
            (keyName) => {
              const obj = cardData?.listing?.custom_attributes[
                keyName as any
              ] as AttributeAssociation;
              const imageSet = new Set();
              return (
                <>
                  <p className="left-column tab">
                    {formatAttributeName(obj.attr_name_en, obj.attr_name_ar)}
                  </p>
                  <div className="right-column tab">
                    {getUniqueValues(obj).map((item, k) => {
                      return <Fragment key={k}>{item}, </Fragment>;
                    })}
                  </div>
                  <div className="third-column">
                    <div className="images">
                      {obj.values_images_items?.map((item, l) => {
                        if (imageSet.has(item.value_en)) {
                          return;
                        }
                        imageSet.add(item.value_en);
                        return (
                          <Fragment key={l}>
                            {!item.image || item.image === null ? (
                              <p>N/A-</p>
                            ) : (
                              <ShowImage
                                image={item.image}
                                onClick={() => {
                                  handleClick(
                                    cardData?.listing?.images?.findIndex(
                                      (i) => i.id === item.image?.id,
                                    ),
                                  );
                                }}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <p className="forth-column tab">Store</p>
                </>
              );
            },
          )}
        </div>
        {Object.keys(cardData?.listing?.custom_attributes || {}).length +
          Object.keys(cardData?.listing?.official_attributes || {}).length <
          1 && (
          <h5
            style={
              fieldsNullFlags?.has_attributes
                ? {
                    padding: '10px',
                    background: 'white',
                    color: Colors.blueMain,
                    borderRadius: '5px',
                    fontSize: '14px',
                  }
                : missingInfoStyle
            }
          >
            No Attributes Were Given For This Item
          </h5>
        )}
      </div>
      {showAppendAttributeValuesModal && (
        <>
          <Modal
            isOpen={isOpen}
            size={'xl'}
            placement={'center'}
            onOpenChange={onOpenChange}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    <h4>Append Attribute Values</h4>
                  </ModalHeader>
                  <ModalBody>
                    <h6 style={{ color: 'green' }}>
                      Enter the English and Arabic values you want to append to
                      the attribute:
                    </h6>
                    <Row>
                      {enAttributeValue !== '' &&
                      attributeToUpdate?.type === AttributeType.BOOLEAN ? (
                        <div className="d-flex align-items-center gap-2">
                          <label>Boolean Value:</label>
                          <Switch
                            isSelected={enAttributeValue === 'true'}
                            onChange={(e) =>
                              setEnAttributeValue(
                                e.target.checked ? 'true' : 'false',
                              )
                            }
                            color="success"
                          />
                        </div>
                      ) : enAttributeValue !== '' &&
                        attributeToUpdate?.type === AttributeType.NUMERIC ? (
                        <Input
                          className="max-w-xs"
                          label="Enter Number"
                          color="success"
                          variant="bordered"
                          placeholder={enAttributeValue}
                          autoFocus
                          // Add listener for Enter key to submit
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              submitNewValues();
                            }
                          }}
                          type="number"
                          onChange={(e) => setEnAttributeValue(e.target.value)}
                        />
                      ) : (
                        <>
                          {' '}
                          <Col
                            lg={6}
                            className="mb-2 d-flex flex-column justify-content-center gap-2"
                          >
                            {isSelectAttribute(attributeToUpdate) && (
                              <Autocomplete
                                className="max-w-xs"
                                color={'success'}
                                variant={'bordered'}
                                label="Select English Value"
                                allowsCustomValue={true}
                                autoFocus
                                placeholder="Search or select a value"
                                onSelectionChange={(key) => {
                                  const selectedIndex = Number(key);
                                  const selectedEnValue =
                                    attributeToUpdate?.official_values_en?.[
                                      selectedIndex
                                    ];
                                  const selectedArValue =
                                    attributeToUpdate?.official_values_ar?.[
                                      selectedIndex
                                    ];

                                  if (selectedEnValue) {
                                    setEnAttributeValue(selectedEnValue);
                                  }
                                  if (selectedArValue) {
                                    setArAttributeValue(selectedArValue);
                                  }
                                }}
                                onInputChange={(e: string) => {
                                  setEnAttributeValue(e);
                                }}
                              >
                                {(
                                  attributeToUpdate?.official_values_en ?? []
                                ).map((value, index) => (
                                  <AutocompleteItem
                                    key={index.toString()}
                                    color={'success'}
                                    variant={'shadow'}
                                  >
                                    {value}
                                  </AutocompleteItem>
                                ))}
                              </Autocomplete>
                            )}
                          </Col>
                          <Col
                            lg={6}
                            className="mb-2 d-flex flex-column justify-content-center gap-2"
                          >
                            {isSelectAttribute(attributeToUpdate) && (
                              <Autocomplete
                                className="max-w-xs"
                                label="Select Arabic Value"
                                color={'success'}
                                variant={'bordered'}
                                inputValue={arAttributeValue}
                                allowsCustomValue={true}
                                placeholder="Search or select a value"
                                onSelectionChange={(key) => {
                                  const selectedIndex = Number(key);
                                  const selectedEnValue =
                                    attributeToUpdate?.official_values_en?.[
                                      selectedIndex
                                    ];
                                  const selectedArValue =
                                    attributeToUpdate?.official_values_ar?.[
                                      selectedIndex
                                    ];

                                  if (selectedArValue) {
                                    setArAttributeValue(selectedArValue);
                                  }
                                  if (selectedEnValue) {
                                    setEnAttributeValue(selectedEnValue);
                                  }
                                }}
                                onInputChange={(e: string) => {
                                  setArAttributeValue(e);
                                }}
                              >
                                {(
                                  attributeToUpdate?.official_values_ar ?? []
                                ).map((value, index) => (
                                  <AutocompleteItem
                                    key={index.toString()}
                                    color={'success'}
                                    variant={'shadow'}
                                  >
                                    {value}
                                  </AutocompleteItem>
                                ))}
                              </Autocomplete>
                            )}
                          </Col>
                        </>
                      )}
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onPress={() => {
                        setArAttributeValue('');
                        setEnAttributeValue('');
                        setAttributeToUpdate(undefined);
                        setShowAppendAttributeValuesModal(false);
                        onClose();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      color="success"
                      variant="shadow"
                      onPress={() => {
                        submitNewValues();
                      }}
                    >
                      {attributeToUpdate?.type === AttributeType.BOOLEAN
                        ? `Update to ${enAttributeValue}`
                        : attributeToUpdate?.type === AttributeType.NUMERIC
                          ? `Update to ${enAttributeValue}`
                          : isSelectAttribute(attributeToUpdate) &&
                              'official_values_en' in attributeToUpdate &&
                              attributeToUpdate.official_values_en?.includes(
                                enAttributeValue,
                              )
                            ? `Update ${enAttributeValue} ${EN_AR_TRANSLATION_SPLIT_CHAR} ${arAttributeValue}`
                            : `Append ${enAttributeValue} ${EN_AR_TRANSLATION_SPLIT_CHAR} ${arAttributeValue}`}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </>
      )}
      <PopupWithConfirmation
        headerTitle="Do you want to change the value to another EXISTING one?"
        bodyText="This will essentially bulk edit from the current value to the selected existing one."
        buttonTwoText="Yes"
        buttonOneText="No"
        open={confirmModal}
        withTimerOfNSeconds={null}
        onButtonOneClick={() => {
          setWantsToChangeToAnotherValue(false);
          setConfirmModal(false);
        }}
        onButtonTwoClick={async () => {
          await CategoriesRepository.bulkEditAttributes(
            [
              {
                listing_id: cardData.listing!.id,
                new_value_en: enAttributeValue,
              },
            ],
            attributeToUpdate!.id,
            attributeToUpdate!.type,
          ).then((res) => {
            if (!res) return;
            setArAttributeValue('');
            setEnAttributeValue('');
            setAttributeToUpdate(undefined);
            setShowAppendAttributeValuesModal(false);
            setWantsToChangeToAnotherValue(false);
            setConfirmModal(false);
          });
        }}
      />
      {image && (
        <Lightbox
          mainSrc={image.public_s3_url}
          imageTitle={`# ${currentImage.id} ` + (image.alt || 'No Alt Text')}
          mainSrcThumbnail={image.public_s3_url}
          nextSrc={
            nextImage.public_s3_url || nextImage.get_object_presigned_url
          }
          nextSrcThumbnail={nextImage.alt}
          prevSrc={
            prevImage.public_s3_url || prevImage.get_object_presigned_url
          }
          prevSrcThumbnail={prevImage.alt}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </>
  );
};
